import React, { useEffect, useRef, useState, useContext } from "react";
import { AppContext } from "../context";
import { ping, makeRequest, userLogs , } from "../context/utils";
let pingData = [];

const Ping = () => {
  const { showAlert, setShowAlert, screen, stopPing, inspectionId } =
    useContext(AppContext);
  const pRef = useRef();
  const pRef2 = useRef();
  const pRef3 = useRef();
  const [config, setConfig] = useState(false);

  const checkToken = async () => {
    const userToken = window.location.hash.split("#")[1];
    if (userToken === undefined) {
    } else {
      const res = await makeRequest("/checkToken", { token: userToken });
      const data = await res.json();
      if (data.status === "error") {
      } else {
        setConfig(data.configData);
      }
    }
  };

  config === false && checkToken();

  useEffect(() => {
    config &&
      setInterval(async () => {
        if (!navigator.onLine) {
          setShowAlert(
            Object.keys(config["errors"]).includes("no-internet")
              ? config["errors"]["no-internet"]
              : "No Internet connection"
          );
          pRef.current.style.backgroundColor = "red";
          pRef2.current.style.backgroundColor = "red";
          pRef3.current.style.backgroundColor = "red";
        }
        let startTime = new Date().getTime();
        let data = await ping();
        if (stopPing) {
          if (data) {
            let endTime = new Date().getTime();
            let difference = endTime - startTime;
            if (pingData.length < 10) {
              pingData.push(difference);
            } else {
              let sum = 0;
              pingData.map((e) => {
                sum = sum + e;
              });
              sum = sum / pingData.length;
              console.log({
                position: 10,
                internet_latency: sum.toString(),
                inspectionId,
              });
              userLogs({
                position: 10,
                internet_latency: sum.toString(),
                inspectionId,
              });
              pingData = [];
            }
            if (difference < 120) {
              pRef.current.style.backgroundColor = "#05f043";
              pRef2.current.style.backgroundColor = "#05f043";
              pRef3.current.style.backgroundColor = "#05f043";
              setShowAlert(null);
            }
            if (difference > 120 && difference < 400) {
              pRef.current.style.backgroundColor = "#f7f705";
              pRef2.current.style.backgroundColor = "#f7f705";
              pRef3.current.style.backgroundColor = "#f7f705";
              setShowAlert(null);
            }
            if (difference > 400) {
              pRef.current.style.backgroundColor = "red";
              pRef2.current.style.backgroundColor = "red";
              pRef3.current.style.backgroundColor = "red";
            }
            if (difference > 400 && difference < 800) {
              setShowAlert(null);
            }
            if (difference > 1500) {
              setShowAlert(
                Object.keys(config["errors"]).includes("slow-internet")
                  ? config["errors"]["slow-internet"]
                  : "Slow connection. Please switch to better connection."
              );
            }
          }
        }
      }, 1000);
  }, [config]);

  return (
    <div className="pings">
      <div className="pingBar" style={{ display: stopPing ? "block" : "none" }}>
        <div className="statBar" ref={pRef}></div>
        <div className="statBarTwo" ref={pRef2}></div>
        <div className="statBarThree" ref={pRef3}></div>
      </div>
      <div>
        {Object.keys(config).length != 0 && (
          <p
            style={
              screen === "email" ||
              screen === "instructions" ||
              screen === "form" ||
              screen === "rejection"
                ? { filter: "invert(1)", color: config["colors"]["nav"] }
                : { color: "black" }
            }
          >
            {Object.keys(config["errors"]).includes("connection")
              ? config["errors"]["connection"]
              : "connection"}
          </p>
        )}
      </div>
    </div>
  );
};
export default Ping;
