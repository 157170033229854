import { useState, useRef } from "react";

export function useAppData() {
  const [token, setToken] = useState(null);
  const [retake, setRetake] = useState(null);
  const [config, setConfig] = useState({});
  const [currentBlock, setCurrentBlock] = useState({});
  const [screen, setScreen] = useState("loading");
  const [condition, setCondition] = useState({});
  const [subCondition, setSubCondition] = useState({});
  const [mandatory, setMandatory] = useState({});
  const [subMandatory, setSubMandatory] = useState({});
  const [showOptions, setShowOptions] = useState(true);
  const [showPreview, setShowPreview] = useState(false);
  const [showDescription, setShowDescription] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [isLandscape, setLandscape] = useState(false);
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [imgPosition, setImgPosition] = useState(null);
  const [currentDamage, setCurrentDamage] = useState(0);
  const [fImg, setFImg] = useState(null);
  const [sImg, setSImg] = useState(null);
  const [page, setPage] = useState("inst");
  const [completed, setCompleted] = useState(false);
  const [subCompleted, setSubCompleted] = useState(false);
  const [mandatoryCompleted, setMandatoryCompleted] = useState(false);
  const [subMandatoryCompleted, setSubMandatoryCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [inspectionId, setInspectionId] = useState(null);
  const [location, setLocation] = useState({ latitude: 0, longitude: 0 });
  const [showZeroCamera, setShowZeroCamera] = useState(false);
  const [showQRCamera, setShowQRCamera] = useState(true);
  const [previewZeroImg, setPreviewZeroImg] = useState(null);
  const [previewQRImg, setPreviewQRImg] = useState(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);
  const [pings, setPings] = useState(null);
  const [showAlert, setShowAlert] = useState(null);
  const [stopPing, setStopPing] = useState(true);
  const [clientId, setClientId] = useState(null);
  const [clientUniqueIdentity, setClientUniqueIdentity] = useState("");
  const [currentPage, setCurrentPage] = useState("");
  const [stepCovered, setStepCovered] = useState([]);
  const [autoRotate, setAutoRotate] = useState(false);
  const [phone, setPhone] = useState(null);
  const [damageModuleCompleted, setDamageModuleCompleted] = useState(false);
  const [caseId, setCaseId] = useState("");
  const [hiibCustomValue, setHiibCustomValue] = useState(false);
  const [VINDeteted, setVINDeteted] = useState(false);
  const [feedBackStatus, setFeedBackStatus] = useState("");
  const [totalImageForAnalysis, setTotalImageForAnalysis] = useState([]);
  const [callOnceFeedBack, setCallOnceFeedBack] = useState(true);
  const [totalImageFeedbackMssg, setTotalImageFeedbackMssg] = useState({});
  const [feedBackBtndisabled, setFeedBackBtndisabled] = useState(false);
  const [feedBackMssg, setFeedBackMssg] = useState("");
  const [feedbackReceived, setFeedbackReceived] = useState([]);
  const [showFeedBackWarning, setShowFeedBackWarning] = useState(false);
  const [restrictUserIfallNotGreen, setRestrictUserIfallNotGreen] = useState(true)
  const [imageFeedbackPopupRef, setImageFeedbackPopupRef] = useState(false)

  return {
    restrictUserIfallNotGreen, 
    setRestrictUserIfallNotGreen,
    totalImageFeedbackMssg,
    setTotalImageFeedbackMssg,
    callOnceFeedBack,
    setCallOnceFeedBack,
    totalImageForAnalysis,
    setTotalImageForAnalysis,
    caseId,
    setCaseId,
    damageModuleCompleted,
    setDamageModuleCompleted,
    stepCovered,
    setStepCovered,
    token,
    setToken,
    config,
    setConfig,
    currentBlock,
    setCurrentBlock,
    screen,
    setScreen,
    condition,
    setCondition,
    showOptions,
    setShowOptions,
    clientId,
    setClientId,
    userEmail,
    setUserEmail,
    uploaded,
    setUploaded,
    isLandscape,
    setLandscape,
    count,
    setCount,
    imgPosition,
    setImgPosition,
    currentDamage,
    setCurrentDamage,
    fImg,
    setFImg,
    sImg,
    setSImg,
    page,
    setPage,
    showDescription,
    setShowDescription,
    mandatory,
    setMandatory,
    autoRotate,
    setAutoRotate,
    completed,
    setCompleted,
    mandatoryCompleted,
    setMandatoryCompleted,
    subMandatory,
    setSubMandatory,
    subCompleted,
    setSubCompleted,
    phone,
    setPhone,
    subMandatoryCompleted,
    setSubMandatoryCompleted,
    subCondition,
    setSubCondition,
    showPreview,
    setShowPreview,
    count1,
    setCount1,
    clientUniqueIdentity,
    setClientUniqueIdentity,
    errorMessage,
    setErrorMessage,
    location,
    setLocation,
    inspectionId,
    setInspectionId,
    retake,
    setRetake,
    showZeroCamera,
    setShowZeroCamera,
    currentPage,
    setCurrentPage,
    previewZeroImg,
    setPreviewZeroImg,
    showQRCamera,
    setShowQRCamera,
    previewQRImg,
    setPreviewQRImg,
    scrollX,
    setscrollX,
    scrolEnd,
    setscrolEnd,
    pings,
    setPings,
    showAlert,
    setShowAlert,
    stopPing,
    setStopPing,
    hiibCustomValue,
    setHiibCustomValue,
    VINDeteted,
    setVINDeteted,
    feedBackStatus,
    setFeedBackStatus,
    feedBackBtndisabled,
    setFeedBackBtndisabled,
    feedBackMssg,
    setFeedBackMssg,
    feedbackReceived,
    setFeedbackReceived,
    showFeedBackWarning, 
    setShowFeedBackWarning,
    imageFeedbackPopupRef,
    setImageFeedbackPopupRef
  };
}
