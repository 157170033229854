import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../context";
import {
  finalUpload,
  backupImagesStatus,
  totalImagesUploadStatus,
  userLogs,
  patLogs,
  setFeedBackStatus,
} from "../context/utils";
import Button from "./Button";
import successLoading from "../img/successLoading.gif";
let logsbugs = true;
// import ReactGA from "react-ga4";

var progressBar = require("progressbar.js");
let interval = null;
let d = 0;
let uploadButton = false;
let upValue = 1;

const Success = () => {
  const [barData, setBarData] = useState(0);
  let dRef = useRef(null);
  const pRef = useRef(null);
  const {
    setErrorMessage,
    setScreen,
    stepCovered,
    setStepCovered,
    config,
    inspectionId,
    uploaded,
    setUploaded,
    clientId,
    showAlert,
    setCurrentPage,
  } = useContext(AppContext);

  if (logsbugs) {
    patLogs("Entered to image Uploading page", inspectionId);
    userLogs({ position: 8, last_page: "uploading page", inspectionId });
    // ReactGA.send({
    //   hitType: "pageview",
    //   page: "Uploading Page",
    //   title: "Uploading Page",
    // });
  }
  logsbugs = false;

  const imageStatus = () => {
    const data = totalImagesUploadStatus();
    pRef.current.textContent = `${
      Object.keys(config["success-page"]).includes("ImageUploadingStatusTxt")
        ? config["success-page"]["ImageUploadingStatusTxt"]
        : "Images uploading"
    } ${data.uploadedImage + (upValue ? 0 : 1)} / ${data.totalImage + 1}`;
    // console.log(`Images uploading including uploading  ${data.uploadedImage + (upValue ? 0 : 1)} / ${data.totalImage + 1}`)
    setBarData(
      (data.uploadedImage + (upValue ? 0 : 1)) / (data.totalImage + 1)
    );
    if (upValue === 0) {
      clearInterval(interval);
      setUploaded(true);
      patLogs("All images uploaded , At success page", inspectionId);
      userLogs({ position: 8, last_page: "success page", inspectionId });
      setCurrentPage("Success Page");
    }
  };

  const sendMessageIFrame = () => {
    window.top.postMessage("uploaded", "*");
  };

  const startFinalUpload = async () => {
    if (!backupImagesStatus()) {
      let d = await finalUpload();
      if (d === "usedToken") {
        patLogs("used token", inspectionId);
        clearInterval(interval);
        setErrorMessage(
          "The link has already been used. Please contact your administrator for a new link"
        );
        setScreen("error");
      } else if (d) {
        console.log("INSPECTION_SUBMISSION_SUCCESS")
        upValue = 0;
        sendMessageIFrame();
      }
    } else if (uploadButton) {
      let b = await finalUpload();
      if (b === "usedToken") {
        patLogs("used token", inspectionId);
        clearInterval(interval);
        setErrorMessage(
          "The link has already been used. Please contact your administrator for a new link"
        );
        setScreen("error");
      } else if (d) {
        console.log("INSPECTION_SUBMISSION_SUCCESS")
        upValue = 0;
        sendMessageIFrame();
      }
    }
  };

  useEffect(() => {
    drawProgress().animate(barData);
  }, [barData]);

  const drawProgress = () => {
    dRef.current.innerHTML = null;
    let bar = new progressBar.Circle("#progress-final", {
      strokeWidth: 6,
      easing: "easeInOut",
      duration: 2000,
      color: "#000000",
      trailColor: "#eee",
      trailWidth: 4,
      svgStyle: null,
      step: function (state, circle) {
        let value = Math.round(circle.value() * 100);
        circle.setText(`${value}%`);
      },
    });
    barData && bar.set(d);
    d = barData;
    return bar;
  };

  useEffect(() => {
    interval = setInterval(() => {
      imageStatus();
      startFinalUpload();
    }, 2000);
    setFeedBackStatus("");
  }, []);

  const newUrl = () => {
    window.location.href = "https://webapp-gen.inspektlabs.com/login";
  };

  useEffect(() => {
    uploaded && window.top.postMessage("uploaded", "*");
  }, [uploaded]);

  return uploaded ? (
    <div id="final-screen" className="screen">
      {config["success-page"]["image"].length > 0 && <img className="final-gif" src={config["success-page"]["image"]}></img>}
      <p>{config["success-page"]["text1"]}</p>
      <p>{config["success-page"]["text2"]}</p>
      <p>
        {config["success-page"]["support-email-text"]}{" "}
        <span style={{ fontWeight: "bold" }}>
          {" "}
          {config["success-page"]["support-email"]}{" "}
        </span>
      </p>
      <br></br>
      <p style={{ fontSize: "10px", fontWeight: "lighter" }}>
        {config["success-page"]["inspection-id"]}: {inspectionId}
      </p>
      {clientId === "kshitij_testing" && (
        <div className="modal-btn-container">
          <button
            className="btn btn-blue"
            style={{ backgroundColor: "rgb(0, 108, 202" }}
            onClick={() => newUrl()}
          >
            Web Gen
          </button>
        </div>
      )}
    </div>
  ) : (
    <div id="final-screen" className="screen">
      <div ref={dRef} className="progress-final" id="progress-final"></div>
      <div
        style={{
          justifyContent: "space-around",
          alignItems: "center",
          width: "230px",
          display: "flex",
        }}
      >
        <p ref={pRef}></p>
        <img src={successLoading}></img>
      </div>
      <h4>{config["success-page"]["uploading-text"]}</h4>
      <p>{config["success-page"]["tab-text"]}</p>
      <div className="InternetWarning">
        <p>{showAlert && showAlert}</p>
      </div>
      {backupImagesStatus() && (
        <div className="modal-btn-container">
          <button
            className="btn btn-blue"
            style={{ backgroundColor: "rgb(0, 108, 202" }}
            onClick={() => (uploadButton = true)}
          >
            Retry Uploading
          </button>
        </div>
      )}
    </div>
  );
};

export default Success;
