import Navbar from "./Navbar.js";
import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../context";
import {
  setLog,
  getCountData,
  formUpload,
  userLogs,
  patLogs,
  
} from "../context/utils";
// import ReactGA from "react-ga4";

let upload;
let uploaded = true;
export default function Form() {
  const {
    stepCovered,
    setStepCovered,
    setScreen,
    setLandscape,
    config,
    setCurrentPage,
    inspectionId,
  } = useContext(AppContext);
  const [inputValidationError, setInputValidationError] = useState([]);
  const [formdata, setFormData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [wrongFile, setWrongFile] = useState(null);
  const [corrupted, setCorrupted] = useState(false);
  const [formDocuments, setFormDocuments] = useState({});
  const [refreshed, setRefreshed] = useState(true);

  const land = () => window.innerWidth > window.innerHeight * 1.2;
  patLogs("Entered to Form Page", inspectionId);
  // ReactGA.send({ hitType: "pageview", page: "Form Page", title: "Form Page" });
  userLogs({ position: 8, last_page: "form page", inspectionId });
  const handleFileSelect = (e, f) => {
    const file = e.target.files[0];
    if (
      file &&
      (file.type === "image/jpeg" || file.type === "application/pdf")
    ) {
      setDisabled(false);
      setWrongFile("");
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFormDocuments({ ...formDocuments, [f]: reader.result });
      };
      reader.onerror = () => {
        setDisabled(true);
        setCorrupted(true);
        setWrongFile(f);
      };
    } else {
      setDisabled(true);
      setWrongFile(f);
    }
  };

  const uploadForm = () => {
    patLogs("procced button is clicked", inspectionId);
    let jsonFileWithDocuments = {};
    let jsonFile = "";
    let impData = [];
    jsonFileWithDocuments = { ...formdata, documents: formDocuments };
    jsonFile = formdata;
    if (config["form-page"]["impData"]) {
      impData = [
        config["form-page"]["impData"],
        formdata[config["form-page"]["impData"]]
          ? formdata[config["form-page"]["impData"]]
          : "",
      ];
    } else {
      impData = ["", ""];
    }
    upload = setInterval(() => {
      let data = getCountData();
      if (data && uploaded) {
        uploaded = false;
        formUpload(
          JSON.stringify(jsonFileWithDocuments),
          JSON.stringify(jsonFile),
          impData
        );
        clearInterval(upload);
      }
    }, [1000]);
    setLog(`${config["form-page"]["tag"]} was completed`);
    patLogs("Form page is completed", inspectionId);
    setScreen("menu");
  };

  window.onresize = () => {
    setLandscape(land());
  };

  const radioFunc = (a, e) => {
    setRefreshed(refreshed ? false : true);
    formdata[a]
      ? formdata[a] === e
        ? delete formdata[a]
        : setFormData({ ...formdata, [a]: e })
      : setFormData({ ...formdata, [a]: e });
  };

  const checkBoxFunc = (a, e) => {
    setRefreshed(refreshed ? false : true);
    formdata[a]
      ? formdata[a].includes(e)
        ? formdata[a].length === 1
          ? delete formdata[a]
          : setFormData({
              ...formdata,
              [a]: formdata[a].filter((q) => q !== e),
            })
        : setFormData({
            ...formdata,
            [a]: formdata[a] ? [...formdata[a], e] : [e],
          })
      : setFormData({
          ...formdata,
          [a]: formdata[a] ? [...formdata[a], e] : [e],
        });
  };

  const inputCheck = (f, e, validation) => {
    if (validation[0] === "") {
      setFormData({ ...formdata, [f]: e.target.value });
    } else {
      let regex = new RegExp(validation[0]);
      if (regex.test(e.target.value)) {
        setFormData({ ...formdata, [f]: e.target.value });
        setInputValidationError(inputValidationError.filter((e) => e !== f));
      } else {
        formdata[f] && setFormData({ ...delete formdata[f] });
        setInputValidationError([...inputValidationError, f]);
      }
    }
  };

  const deleteSelectedFile = (f) => {
    setRefreshed(refreshed ? false : true);
    document.getElementById(f).value = "";
    formDocuments[f] && delete formDocuments[f];
    setCorrupted(false);
    setWrongFile(null);
  };

  useEffect(() => {
    config["form-page"].tag_mandatory.filter(
      (e) => !formdata[e] && !formDocuments[e]
    ).length
      ? setDisabled(true)
      : setDisabled(false);
  }, [formdata, formDocuments, refreshed]);

  return (
    <div id="container" style={{ overFlow: "scroll" }}>
      <div className="form">
        <h1>{config["form-page"]["header"]}</h1>
        {Object.keys(config["form-page"].inputTag).map((f) => (
          <div key={f} className="div-input">
            <label className="label">
              {f}{" "}
              {config["form-page"].tag_mandatory?.includes(f) && <span>*</span>}
              {inputValidationError.includes(f) && (
                <span>
                  {"("} Enter Valid {f} {")"}
                </span>
              )}
            </label>
            <input
              type="text"
              placeholder={config["form-page"].inputTag[f][1]}
              value={formdata.f}
              onChange={(e) =>
                inputCheck(f, e, config["form-page"].inputTag[f])
              }
            />
          </div>
        ))}
        {Object.keys(config["form-page"].radio).map((a) => (
          <div>
            <label className="label">
              {a}{" "}
              {config["form-page"].tag_mandatory?.includes(a) && <span>*</span>}
            </label>
            <div className="div-radio">
              {Object.values(config["form-page"].radio[a]).map((c) => (
                <div className="label-div">
                  <input
                    type="radio"
                    value={c}
                    checked={formdata[a] === c}
                    onClick={() => radioFunc(a, c)}
                  />
                  <p>{c}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
        {Object.keys(config["form-page"].checkbox).map((a) => (
          <div>
            <label className="label">
              {a}{" "}
              {config["form-page"].tag_mandatory?.includes(a) && <span>*</span>}
            </label>
            <div className="div-radio">
              {Object.values(config["form-page"].checkbox[a]).map((c) => (
                <div className="label-div">
                  <input
                    type="checkbox"
                    value={c}
                    checked={formdata[a]?.includes(c)}
                    onClick={() => checkBoxFunc(a, c)}
                  />
                  <p>{c}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
        {Object.keys(config["form-page"].select).map((a) => (
          <div className="div-input">
            <label className="label">
              {a}{" "}
              {config["form-page"].tag_mandatory?.includes(a) && <span>*</span>}
            </label>
            <select
              className="form-select"
              value={formdata.cartype}
              onChange={(e) =>
                setFormData({ ...formdata, [a]: e.target.value })
              }
            >
              <option
                className="form-select-option"
                value=""
                style={{ display: "none" }}
              ></option>
              {Object.values(config["form-page"].select[a]).map((g) => (
                <option className="form-select-option" value={g}>
                  {g}
                </option>
              ))}
            </select>
          </div>
        ))}
        <div>
          {config["form-page"].documents.map((f, i) => (
            <div className="documents">
              <label className="label">
                {f}{" "}
                {config["form-page"].tag_mandatory?.includes(f) && (
                  <span>*</span>
                )}
              </label>
              <div className="inputFile">
                <div className="radiodiv">
                  <input
                    type="file"
                    id={f}
                    accept="image/pdf, image/jpg"
                    onChange={(e) => handleFileSelect(e, f)}
                  />
                  {wrongFile === f && !corrupted && (
                    <p>{config["form-page"]["alertImageType"]}</p>
                  )}
                  {wrongFile === f && corrupted && (
                    <p>{config["form-page"]["alertImageCorrupted"]}</p>
                  )}
                </div>
                <div
                  className="imageRemovebutton"
                  onClick={() => deleteSelectedFile(f)}
                >
                  X
                </div>
                <div></div>
              </div>
            </div>
          ))}
        </div>
        {/* <div className='InternetWarning'><p>{showAlert && showAlert}</p></div> */}
        <div className="form-btn">
          <button
            disabled={disabled}
            style={{
              backgroundColor: disabled
                ? "#91b2cf"
                : config["form-page"]["btn"],
            }}
            onClick={uploadForm}
          >
            {config["form-page"]["btnText"]}
          </button>
        </div>
      </div>
    </div>
  );
}
