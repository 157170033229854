import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AppContext } from "../context";
import {
  addImageToList,
  setLog,
  userLogs,
  patLogs,
  imageFeedbackLambda,
  setFeedBackStatus,
  feedBackStatus
} from "../context/utils";
import cameraImg from "../img/camera.png";
import Button from "./Button";
import Rotate from "./Rotate";
import leftArrow from "../img/left.png";
import rightArrow from "../img/right.png";
import car_inspection from "../img/car_inspection.png";
import white_tick from "../img/white_tick.png";

// import ReactGA from "react-ga4";
let count = 0;
let logBugs = true;
let currentSubBlock = "";
let currentTag = "";
let imageCaptured = false;
let image = {};
let alignment = false;
let mandatoryMissing = [];
let subMissing = [];
let cameraStarted = false;
let track = null;
let reservedData = null;
let tempStoredFeedBack = {};
let totalImageFeedbackMssg = {};
let feedBackImages;

const MultipleImage = () => {
  const {
    // totalImageFeedbackMssg,
    // setTotalImageFeedbackMssg,
    callOnceFeedBack,
    setCallOnceFeedBack,
    totalImageForAnalysis,
    setTotalImageForAnalysis,
    stepCovered,
    setStepCovered,
    clientId,
    showAlert,
    condition,
    setCondition,
    setScreen,
    currentBlock,
    showOptions,
    setShowOptions,
    config,
    isLandscape,
    setLandscape,
    mandatory,
    setMandatory,
    subMandatory,
    setSubMandatory,
    subCompleted,
    setSubCompleted,
    setCurrentPage,
    inspectionId,
    subMandatoryCompleted,
    setSubMandatoryCompleted,
    subCondition,
    setSubCondition,
    count1,
    setCount1,
    scrollX,
    setscrollX,
    scrolEnd,
    setscrolEnd,
    feedBackBtndisabled,
    setFeedBackBtndisabled,
    feedBackMssg,
    setFeedBackMssg,
    feedbackReceived,
    setFeedbackReceived,
    setImageFeedbackPopupRef
  } = useContext(AppContext);

  const land = () => window.innerWidth > window.innerHeight * 1.2;
  const [instructions, setInstructions] = useState(null);
  const [currentBlockItem, setCurrentBlockItem] = useState(currentBlock["values"]);

  window.onresize = () => {
    setLandscape(land());
    if (land()) {
      if (cameraStarted) startCamera();
    }
  };

  useEffect(() => {
    const setDimensions = () => {

      const processElement = document.querySelector(".process");
      if (processElement) {
        processElement.style.height = `${window.innerHeight}px`;
      }
    };

    // Set dimensions on load
    setDimensions();

    // Update dimensions on window resize
    window.addEventListener('resize', setDimensions);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', setDimensions);
    };
  }, [window.innerHeight]);

  const [reduce, setReduce] = useState(false);
  if (logBugs) {
    patLogs(`Entered to ${currentBlock.tag}`, inspectionId);
    // ReactGA.send({
    //   hitType: "pageview",
    //   page: `${currentBlock.tag}`,
    //   title: `${currentBlock.tag}`,
    // });
    userLogs({
      position: 8,
      last_page: `${currentBlock.tag} Module`,
      inspectionId,
    });
  }
  logBugs = false;
  const singleScreen = useRef();
  const videoRef = useRef();
  const canvasRef = useRef();
  const startBtn = useRef();
  const imgRef = useRef();
  const modalRef = useRef();
  const divImgRef = useRef();
  let scrl = useRef();
  const feedBackPopUp = useRef();

  const constraints = {
    video: Object.keys(config).includes("aspectRatio")
      ? {
        width: Object.keys(config).includes("resolution")
          ? config["resolution"]["width"]
          : 1280,
        height: Object.keys(config).includes("resolution")
          ? config["resolution"]["height"]
          : 720,
        facingMode: "environment",
        aspectRatio: 4 / 3,
        zoom: { ideal: Object.keys(config).includes("zoom") ? config["zoom"] ? 0.5 : 1 : 0.5 },
      }
      : {
        width: Object.keys(config).includes("resolution")
          ? config["resolution"]["width"]
          : window.innerWidth,
        height: Object.keys(config).includes("resolution")
          ? config["resolution"]["height"]
          : window.innerHeight,
        facingMode: "environment",
        zoom: { ideal: Object.keys(config).includes("zoom") ? config["zoom"] ? 0.5 : 1 : 0.5 },
      },
    audio: false,
  };

  const startCamera = () => {
    try {
      if (reservedData) {
        divImgRef.current.src = reservedData;
        imgRef.current.style.display = "block";
      }
      cameraStarted = true;
      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        window.stream = stream;
        track = stream.getTracks()[0];
        videoRef.current.srcObject = stream;
        setTimeout(() => {
          startBtn.current.style.display = "block";
        }, 500);
      });
    }
    catch (err) {
      console.log(err)
    }
  };

  const captureImg = () => {
    patLogs("capture image button clicked", inspectionId);
    const cnv = canvasRef.current;
    const vw = videoRef.current;
    cnv.width = vw.videoWidth;
    cnv.height = vw.videoHeight;
    cnv.getContext("2d").drawImage(vw, 0, 0);
    const imgData = cnv.toDataURL("image/jpeg");
    divImgRef.current.src = imgData;
    reservedData = imgData;
    let region = Object.keys(config["video-page"]).includes("clientRegion")
      ? config["video-page"]["clientRegion"]
      : "eu";
    image = {
      name: `${currentTag}.jpg`,
      imgData: cnv.toDataURL("image/jpeg"),
      tag: `${currentBlock.tag}: ${currentTag}`,
      web_region: null,
      angle: null,
      region: region,
      videoData: null,
      dialStatus: null,
      currentTag,
    };
    imgRef.current.style.display = "block";
  };

  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const imageRetake = () => {
    patLogs("retake button clicked to capture image again", inspectionId);
    reservedData = null;
    imgRef.current.style.display = "none";
  };

  const goBack = () => {
    if (subCompleted) {
      patLogs("back button clicked", inspectionId);
      modalRef.current.style.display = "flex";
      logBugs = true;
      return;
    }
    cameraStarted = false;
    setSubCompleted(false);
    setSubMandatoryCompleted(false);
    currentSubBlock = "";
    currentTag = "";
    alignment = false;
    setScreen("menu");
  };

  const removeModal = () => {
    patLogs(
      `user clicked cross button to close warning and move to ${currentBlock.id}`,
      inspectionId
    );
    modalRef.current.style.display = "none";
  };
  const modal = () => {
    if (subCompleted) {
      imageAnalysis();
      return;
    }
    if (!subMandatoryCompleted || !subCompleted) {
      patLogs(
        `clicked to submit button, Warning given to user,${subMissing.join(
          ", "
        )} is not completed`,
        inspectionId
      );
      modalRef.current.style.display = "flex";
      return;
    }
  };

  const setFeedbackReceivedHanlder = (currentTag) => {
    if (feedBackImages) {
      let temp = feedBackImages.filter((e) => e !== currentTag);
      feedBackImages = temp;
    }
  }

  const imageNext = () => {
    logBugs = true;
    patLogs(
      `tick button clicked image sent for uploading... and return to ${currentBlock.id}`,
      inspectionId
    );
    reservedData = null;
    if (imageCaptured) return;
    cameraStarted = false;
    imgRef.current.style.display = "none";
    imageCaptured = true;
    window.stream = null;
    track.stop();
    let temp2 = subCondition;
    temp2[currentBlock.id][currentSubBlock] = "completed";
    setSubCondition(temp2);
    currentBlock.values.map((item) => {
      if (item.name === currentSubBlock && item.mandatory) {
        let temp3 = subMandatory;
        temp3[currentBlock.id][currentSubBlock] = "completed";
        setSubMandatory(temp3);
      }
    });
    setLog(`${currentTag} was captured`);
    //send image here----
    if (
      Object.keys(config).includes("imageFeedback") &&
      currentBlock.tag === "360 Image" &&
      feedbackReceived.length === 0
    ) {
      setTotalImageForAnalysis([...totalImageForAnalysis, image]);
      checkImage(currentTag);
      count = count + 1;
    } else {
      if (feedBackImages && feedBackImages.length > 0) {
        setFeedbackReceivedHanlder(currentTag)
      }
      setFeedbackReceived(feedbackReceived.filter((e) => e !== currentTag));
      addImageToList(image);
      if (feedbackReceived.length === 1) {
        setFeedBackBtndisabled(false);
        setFeedBackStatus("noFeedback");
      }
    }
    checkAll();
    if (process.env.REACT_APP_IMAGE_SLIDER) {
      sortBlock();
    }
    setCount1(count1 + 1);
    setShowOptions(true);
    if (Object.keys(config).includes("imageFeedback") && currentBlock.tag === "360 Image" && feedbackReceived.length === 1) {
      setImageFeedbackPopupRef(true);
      modal();
    }
  };

  const checkImage = async (tag) => {
    let data = await imageFeedbackLambda({
      inspection_id: inspectionId,
      image: image["imgData"].replace(/^data:image\/jpeg;base64,/, ""),
      tag: tag,
      client_id: clientId,
    });
    if (data["feedback"].length > 0) {
      console.log({ [tag]: data["feedback"] });
      totalImageFeedbackMssg = {
        ...totalImageFeedbackMssg,
        [tag]: data["feedback"],
      };
      console.log(totalImageFeedbackMssg);
    }
  };

  const sortBlock = () => {
    let tempCurrentBlock = currentBlockItem;
    if (subMissing.length === 0 && mandatoryMissing.length === 0 && (feedBackImages && feedBackImages.length > 0)) {
      tempCurrentBlock.sort((a, b) => {
        const aHasTag = feedBackImages.includes(a.tag);
        const bHasTag = feedBackImages.includes(b.tag);

        if (aHasTag && !bHasTag) return -1;
        if (!aHasTag && bHasTag) return 1;

        return 0;
      });
    }
    else {
      const subMissingSet = new Set(subMissing);
      const subMandatoryMissingSet = new Set(mandatoryMissing);

      tempCurrentBlock.sort((a, b) => {
        const aMandatoryIncomplete = subMandatoryMissingSet.has(a.name);
        const bMandatoryIncomplete = subMandatoryMissingSet.has(b.name);

        const aIncomplete = subMissingSet.has(a.name);
        const bIncomplete = subMissingSet.has(b.name);

        // Sort by mandatory and incomplete status
        if (aMandatoryIncomplete && !bMandatoryIncomplete) return -1;
        if (!aMandatoryIncomplete && bMandatoryIncomplete) return 1;

        if (aMandatoryIncomplete && bMandatoryIncomplete) return 0;

        if (aIncomplete && !bIncomplete) return -1;
        if (!aIncomplete && bIncomplete) return 1;

        return 0;
      });
    }
    setCurrentBlockItem(tempCurrentBlock);
  }

  const checkAll = () => {
    let tempSubCheck = true;
    let tempMandCheck = true;
    mandatoryMissing = [];
    subMissing = [];
    currentBlock.values.map((item) => {
      if (subCondition[currentBlock.id][item.name] === "pending") {
        tempSubCheck = false;
        subMissing.push(item.name);
      }
      if (item.mandatory) {
        if (subMandatory[currentBlock.id][item.name] === "pending") {
          tempMandCheck = false;
          mandatoryMissing.push(item.name);
        }
      }
    });

    if (tempSubCheck) {
      setSubCompleted(true);
    }
    if (tempMandCheck) {
      setSubMandatoryCompleted(true);
    }
  };

  const back = () => {
    patLogs(
      `back button is clicked and return to ${currentBlock.id}`,
      inspectionId
    );
    currentSubBlock = "";
    currentTag = "";
    imageCaptured = true;
    cameraStarted = false;
    window.stream = null;
    track.stop();
    setShowOptions(true);
  };

  const imageAnalysis = () => {
    if (
      Object.keys(config).includes("imageFeedback") &&
      currentBlock.tag === "360 Image"
    ) {
      if (callOnceFeedBack) {
        setCallOnceFeedBack(false);
        removeModal();
        feedBackPopUp.current.style.display = "flex";
        setFeedBackStatus("checking");
        setTimeout(() => {
          if (Object.keys(totalImageFeedbackMssg).length > 0) {
            setFeedbackReceived(Object.keys(totalImageFeedbackMssg));
            setFeedBackBtndisabled(true);
            setFeedBackStatus("Feedback");
          }
          totalImageForAnalysis.map((e) => {
            if (!Object.keys(totalImageFeedbackMssg).includes(e.currentTag)) {
              addImageToList(e);
            } else {
              setFeedBackStatus("Feedback");
            }
          });
          console.log(totalImageFeedbackMssg, totalImageForAnalysis);
          if (Object.keys(totalImageFeedbackMssg).length === 0) {
            setFeedBackStatus("noFeedback");
            return;
          }
        }, 20000);
      } else {
        finsh();
        alignment = false;
      }
    } else {
      finsh();
      alignment = false;
    }
  };

  const finsh = () => {
    if (!subMandatoryCompleted) return;
    patLogs(`${currentBlock.id} is completed`, inspectionId);
    cameraStarted = false;
    let temp = condition;
    if (currentBlock.mandatory) {
      let temp1 = mandatory;
      temp1[currentBlock.id] = "completed";
      setMandatory(temp1);
    }
    image = {};
    currentSubBlock = "";
    currentTag = "";
    imageCaptured = false;
    temp[currentBlock.id] = "completed";
    setLog(`${currentBlock["tag"]} was finished`);
    setSubCompleted(false);
    setSubMandatoryCompleted(false);
    setCondition(temp);
    setScreen("menu");
  };

  useEffect(() => {
    console.log(totalImageFeedbackMssg);
    setLandscape(land());
    checkAll();
    setCount1(count1 + 1);
    if (currentBlock["values"].length <= 3) {
      alignment = true;
    }
    imageCaptured = true;
    currentBlock["values"].map((e) => {
      if (e["name"].length > 14) {
        setReduce(true);
      }
    });
    if (feedbackReceived.length > 0) {
      feedBackImages = feedbackReceived;
    }
    if (process.env.REACT_APP_IMAGE_SLIDER) {
      sortBlock();
    }
  }, []);

  const showFeedBackMssg = (value) => {
    setFeedBackMssg(value);
    console.log(value);
  };

  const captureFeedBackImage = (item) => {
    setFeedBackMssg("");
    patLogs(`${item["tag"]} is clicked`, inspectionId);
    currentSubBlock = item["name"];
    currentTag = item["tag"];
    setShowOptions(false);
    imageCaptured = false;
    startCamera();
    Object.keys(item).includes("cameraInstruction") &&
      setInstructions(item["cameraInstruction"]);
  };

  const langConverstion = (value) => {
    if (Object.keys(config["imageFeedback"]["ReasonMssg"]).includes(value)) {
      return config["imageFeedback"]["ReasonMssg"][value]
    } else if (value.includes("You did not capture these parts in the image:")) {
      if (Object.keys(config["imageFeedback"]["ReasonMssg"]).includes("You did not capture these parts in the image:")) {
        let parts = value.split(":")[1].split(",")
        let partCoversion = ""
        parts.map(e => {
          if (Object.keys(config["imageFeedback"]["ReasonMssg"]).includes(e.trim())) {
            partCoversion = `${partCoversion}, ${config["imageFeedback"]["ReasonMssg"][e.trim()]}`
          } else {
            partCoversion = e.trim()
          }
        })
        return `${config["imageFeedback"]["ReasonMssg"]["You did not capture these parts in the image:"].trim()} ${partCoversion}`
      } else {
        return value
      }
    } else if (value.includes("You captured the wrong side. Please capture")) {
      if (Object.keys(config["imageFeedback"]["ReasonMssg"]).includes("You captured the wrong side. Please capture # again.")) {
        let detectRegion = config["imageFeedback"]["ReasonMssg"][value.split("#")[1].trim()] || value.split("#")[1].trim()
        return `${config["imageFeedback"]["ReasonMssg"]["You captured the wrong side. Please capture # again."].split("#")[0].trim()} ${detectRegion} ${config["imageFeedback"]["ReasonMssg"]["You captured the wrong side. Please capture # again."].split("#")[1].trim()}`
      } else {
        return value
      }

    } else if (value.includes("You captured the wrong side. You captured ")) {
      if (Object.keys(config["imageFeedback"]["ReasonMssg"]).includes("You captured the wrong side. You captured # in stead of # .")) {
        let detectRegion1 = config["imageFeedback"]["ReasonMssg"][value.split("#")[1].trim()] || value.split("#")[1].trim()
        let detectRegion2 = config["imageFeedback"]["ReasonMssg"][value.split("#")[3].trim()] || value.split("#")[3].trim()
        return `${config["imageFeedback"]["ReasonMssg"]["You captured the wrong side. You captured # in stead of # ."].split("#")[0].trim()} ${detectRegion1} ${config["imageFeedback"]["ReasonMssg"]["You captured the wrong side. You captured # in stead of # ."].split("#")[1].trim()} ${detectRegion2} ${config["imageFeedback"]["ReasonMssg"]["You captured the wrong side. You captured # in stead of # ."].split("#")[2].trim()}`
      } else {
        return value
      }
    }
    else {
      return value
    }
  }

  return (
    <Fragment>
      {!isLandscape ? (
        <Rotate />
      ) : showOptions ? (
        <div className="process" style={{ position: "relative" }}>
          <h2>{currentBlock["id"]}</h2>
          <div ref={feedBackPopUp} className="imageFeedBackPopup">
            <div style={{ zIndex: 5 }}>
              <p style={{ margin: "2px" }}>
                {Object.keys(config).includes("imageFeedback") && Object.keys(config["imageFeedback"]).includes("ImageAnalysisMssg1") ? config["imageFeedback"]["ImageAnalysisMssg1"] : "It will take some time to analyse the images. Please continue to capture other sections"}
              </p>
              <img
                style={{ width: "80px", height: "80px" }}
                src={car_inspection}
              />
              <button
                className="feedbackbtn"
                style={{ backgroundColor: config["colors"]["btn"] }}
                onClick={() => {
                  feedBackPopUp.current.style.display = "none";
                  finsh();
                  alignment = false;
                }}
              >
                {Object.keys(config).includes("imageFeedback") && Object.keys(config["imageFeedback"]).includes("OtherBtnTxt") ? config["imageFeedback"]["OtherBtnTxt"] : "Other Sections"}
              </button>
            </div>
          </div>
          {feedBackMssg.length > 0 && (
            <div style={{ display: "flex" }} className="imageFeedBackPopup">
              <div
                style={{
                  justifyContent: "space-around",
                  textAlign: "left",
                  height: "45vh",
                  width: "55vw",
                  zIndex: 5
                }}
              >
                <h2 style={{ margin: "0px" }}>{Object.keys(config).includes("imageFeedback") && Object.keys(config["imageFeedback"]).includes("ReasonTxt") ? config["imageFeedback"]["ReasonTxt"] : "Reason"}</h2>
                <ul>
                  {feedBackMssg.map((e) => {
                    return <li style={{ padding: "2px 0px" }}>{Object.keys(config).includes("imageFeedback") ? langConverstion(e) : e}</li>;
                  })}
                </ul>
                <button
                  className="feedbackbtn"
                  style={{
                    backgroundColor: config["colors"]["btn"],
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    captureFeedBackImage(tempStoredFeedBack);
                  }}
                >
                  {Object.keys(config).includes("imageFeedback") && Object.keys(config["imageFeedback"]).includes("RecaptureBtnTxt") ? config["imageFeedback"]["RecaptureBtnTxt"] : "Recapture image"}
                </button>
              </div>
            </div>
          )}
          <div className="process-main" style={{ display: "flex" }}>
            <div
              className="left-btn"
              disabled={scrollX === 0}
              onClick={() => slide(-200)}
              style={alignment ? { display: "none" } : {}}
            >
              <img
                src={leftArrow}
                style={scrollX === 0 ? { opacity: "0.3" } : {}}
              />
            </div>
            <div
              className="process-steps"
              ref={scrl}
              onScroll={scrollCheck}
              style={
                alignment
                  ? {
                    display: "flex",
                    justifyContent: "center",
                    width: "100vw",
                  }
                  : {
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "80vw",
                    overflowX: "scroll",
                    scrollBehavior: "smooth",
                  }
              }
            >
              {currentBlockItem.map((item, index) => {
                if (
                  Object.keys(totalImageFeedbackMssg).length > 0 &&
                  Object.keys(totalImageFeedbackMssg).includes(item.tag)
                ) {
                  return (
                    <div key={index}>
                      <div
                        className="menu-options"
                        style={reduce ? { fontSize: "10px" } : null}
                      >
                        <h4
                          style={
                            feedbackReceived.includes(item.tag)
                              ? {
                                color: "red",
                              }
                              : {
                                color: `${config["colors"]["complete-block"]}`,
                              }
                          }
                          key={index}
                        >
                          {item["name"]}
                        </h4>
                        <div
                          className="process-vin"
                          style={
                            feedbackReceived.includes(item.tag)
                              ? {
                                border: "2px solid red",
                              }
                              : {
                                border: `2px solid ${config["colors"]["complete-block"]}`,
                              }
                          }
                          onClick={() => {
                            if (feedbackReceived.includes(item.tag)) {
                              tempStoredFeedBack = item;
                              showFeedBackMssg(
                                totalImageFeedbackMssg[item.tag]
                              );
                            }
                          }}
                        >
                          {
                            !feedbackReceived.includes(item.tag) && <div className="greenCircleTick">
                              <img src={white_tick} alt="1232"></img>
                            </div>
                          }
                          <img src={item["image"]} alt="123"></img>
                        </div>
                      </div>
                    </div>
                  );
                } else if (
                  subCondition[currentBlock.id][item.name] === "completed"
                ) {
                  return (
                    <div key={index}>
                      <div
                        className="menu-options"
                        style={reduce ? { fontSize: "10px" } : null}
                      >
                        {item["mandatory"] ? (
                          <h4
                            style={{
                              color: config["colors"]["complete-block"],
                            }}
                            key={index}
                          >
                            {item["name"]}&#42;
                          </h4>
                        ) : (
                          <h4
                            style={{
                              color: config["colors"]["complete-block"],
                            }}
                            key={index}
                          >
                            {item["name"]}
                          </h4>
                        )}
                        <div
                          className="process-vin"
                          style={{
                            border: `2px solid ${config["colors"]["complete-block"]}`,
                          }}
                        >
                          <div className="greenCircleTick">
                            <img src={white_tick} alt="1232"></img>
                          </div>
                          <img src={item["image"]} alt="123"></img>
                        </div>
                      </div>
                    </div>
                  );
                }
                return (
                  <div key={index}>
                    <div
                      className="menu-options"
                      style={reduce ? { fontSize: "10px" } : null}
                    >
                      {item["mandatory"] ? (
                        <h4 key={index}>{item["name"]}&#42;</h4>
                      ) : (
                        <h4 key={index}>{item["name"]}</h4>
                      )}
                      <div
                        className="process-vin"
                        style={{
                          border: `1px solid ${config["colors"]["incomplete-block"]}`,
                        }}
                        onClick={() => {
                          patLogs(`${item["tag"]} is clicked`, inspectionId);
                          currentSubBlock = item["name"];
                          currentTag = item["tag"];
                          setShowOptions(false);
                          imageCaptured = false;
                          startCamera();
                          Object.keys(item).includes("cameraInstruction") &&
                            setInstructions(item["cameraInstruction"]);
                        }}
                      >
                        <img src={item["image"]} alt="123"></img>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className="right-btn"
              disabled={scrolEnd}
              style={alignment ? { display: "none" } : {}}
              onClick={() => slide(+200)}
            >
              <img
                src={rightArrow}
                style={scrolEnd ? { opacity: "0.3" } : {}}
              />
            </div>
          </div>
          <div style={{ display: "flex" }} className="InternetWarning">
            <p>{showAlert && showAlert}</p>
          </div>
          <div className="modal-btn-container-multipleImage" style={{ display: "flex", backgroundColor: "#F8F7FC" }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'center', width: '100%' }}>
              <Button
                secoundaryBtn="true"
                text={config["multiple-image-page"]["back-btn-text"]}
                disabled={subCompleted}
                onclick={goBack}
                marginDefault="0px 0px 0px 0px"
              />
              <Button
                text={config["multiple-image-page"]["next-btn-text"]}
                disabled={
                  (feedBackBtndisabled &&
                    Object.keys(config).includes("imageFeedback") &&
                    currentBlock.tag === "360 Image") ||
                  !subMandatoryCompleted
                }
                onclick={() => {
                  if (Object.keys(config).includes("imageFeedback") &&
                    currentBlock.tag === "360 Image") {
                    if (
                      feedBackBtndisabled === false
                    ) {
                      modal();
                    }
                  } else {
                    modal();
                  }
                }}
                marginDefault="0px 25px 0px 25px"
              />
              {/* {
                (Object.keys(config).includes("imageFeedback") &&
                  currentBlock.tag === "360 Image" && config["imageFeedback"]["ImageAnalysisCompletePopup"] &&
                  feedBackStatus() === "Feedback") ? "" :
                  <Button
                    text={config["multiple-image-page"]["next-btn-text"]}
                    disabled={
                      (feedBackBtndisabled &&
                        Object.keys(config).includes("imageFeedback") &&
                        currentBlock.tag === "360 Image") ||
                      !subMandatoryCompleted
                    }
                    onclick={() => {
                      if (Object.keys(config).includes("imageFeedback") &&
                        currentBlock.tag === "360 Image") {
                        if (
                          feedBackBtndisabled === false
                        ) {
                          modal();
                        }
                      } else {
                        modal();
                      }
                    }}
                    marginDefault="0px 25px 0px 25px"
                  />
              } */}

            </div>
          </div>
          <div
            ref={modalRef}
            className="menu-modal-container"
            style={{ display: "none" }}
          >
            {!subMandatoryCompleted ? (
              <div className="menu-modal">
                <div className="menu-back-btn">
                  <i className="fas fa-times fa-lg" onClick={removeModal}></i>
                </div>
                <h4>{config["multiple-image-page"]["mandatory-modal-text"]}</h4>
                <h4>{mandatoryMissing.join(", ")}</h4>
              </div>
            ) : subMandatoryCompleted && !subCompleted ? (
              <div className="menu-modal">
                <div className="menu-back-btn">
                  <i className="fas fa-times fa-lg" onClick={removeModal}></i>
                </div>
                <h4>{config["multiple-image-page"]["optional-modal-text"]}</h4>
                <h4>{subMissing.join(", ")}</h4>
                <div className="modal-btn-container">
                  <Button
                    text={config["multiple-image-page"]["modal-btn-next"]}
                    onclick={() => {
                      setStepCovered([...stepCovered]);
                      patLogs(
                        `Warning given to user,${subMissing.join(
                          ", "
                        )} is not completed still user clicked final submit button`,
                        inspectionId
                      );
                      imageAnalysis();
                    }}
                  />
                </div>
              </div>
            ) : subCompleted ? (
              <div className="menu-modal">
                <div className="menu-back-btn">
                  <i className="fas fa-times fa-lg" onClick={removeModal}></i>
                </div>
                <h4>{config["multiple-image-page"]["back-modal-text"]}</h4>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div id="vin-screen" ref={singleScreen}>
          <canvas
            ref={canvasRef}
            id="ios-canvas"
            style={
              Object.keys(config).includes("aspectRatio")
                ? {
                  width: "100vw",
                  height: "100vh",
                  objectFit: "contain",
                  backgroundColor: "black",
                }
                : {}
            }
          ></canvas>
          <video
            ref={videoRef}
            id="videoWindow"
            style={
              Object.keys(config).includes("aspectRatio")
                ? {
                  width: "100vw",
                  height: "100vh",
                  objectFit: "contain",
                  backgroundColor: "black",
                }
                : {}
            }
            autoPlay
            playsInline
          ></video>
          <div
            ref={startBtn}
            style={{
              backgroundImage: `url(${cameraImg})`,
              backgroundSize: "37px 37px",
              backgroundRepeat: "no-repeat",
              objectFit: "cover",
              backgroundPosition: "center",
            }}
            onClick={captureImg}
            id="btn-vin-start"
          ></div>
          <div className="back-btn">
            <i className="fas fa-arrow-left fa-2x" onClick={back}></i>
          </div>
          {instructions && (
            <div className="camerInst">
              <p>{instructions}</p>
            </div>
          )}
          <div
            id="vin-image"
            style={
              Object.keys(config).includes("aspectRatio")
                ? { backgroundColor: "black", objectFit: "contain" }
                : {}
            }
            ref={imgRef}
          >
            <img
              ref={divImgRef}
              style={
                Object.keys(config).includes("aspectRatio")
                  ? { objectFit: "contain" }
                  : { width: "100vw", height: "100vh" }
              }
            />
            <i
              className="fas fa-times fa-2x vin-btn-retake"
              onClick={imageRetake}
            ></i>
            <i
              className="fas fa-check fa-2x vin-btn-next"
              onClick={imageNext}
            ></i>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default MultipleImage;
