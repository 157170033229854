import React, { useEffect, useContext, useRef, useState } from "react";
import Button from "./Button";
import { AppContext } from "../context";
import * as tf from "@tensorflow/tfjs";
import { PartDetector } from "../partdetection_new/PartDetector";
import { Guidance } from "../guidance_new/GuidanceMain";
import drawArc from "../utils_new/DrawArc";
import carsmall from "../img/carsmall.png";
import initImg from "../img/coverage.png";
import indicator from "../img/indicator.svg";
import VINimage from "../img/vin.png";
import Rotate from "./Rotate";
import {
  addImageToList,
  sendCoveredArea,
  setLog,
  lowLightImage,
  actualRegion,
  userLogs,
  patLogs,
  vin_detection,
  getModel,
} from "../context/utils";
import arrowDown from "../img/down_arrow.gif";
import arrowUp from "../img/up_arrow.gif";

import * as constants from "../utils_new/Constants";
// import ReactGA from "react-ga4";
let runOnce = true;
let logbugs = true;
const screenfull = require("screenfull");
var progressBar = require("progressbar.js");
let flowStop = false;
let processCompleted = false;
let minStop = false;
let notCoveredArea = "";
let showOnce = true;
let regionsAndAnglesMap = {};
for (let val of Object.values(constants.REGIONS))
  regionsAndAnglesMap[val] = new Set();
let allActualRegionDataLog = {};
let allActualRegionDataLogsCheck = {};
let finalRegionsMap = {};
let angleStop = true;
let regionsAngleCountThresh = 1;
let lowLightThreshold = 0.75;
let valuesToCountLowLightImage = [1, 2];
let greenStop = true;
let intervalId;
let intervalId2;
let lumaImageCountThreshold = 5;
let lowLightImageData = [];
let captured = 0;
let allActualRegionData = {};
let actualGuidanceRegion;
let seconds = 0;
let minutes = 0;
let WholeLightLumaData = "";
let data = {};
let track = null;
let angleDetectedGyro = [];
let currentUploadedPartCount = null;
let detectMlCheck = true;
let stopShowingNudgesDOWN = true;
let stopShowingNudgesUP = true;
let stopMlModel = true;
let vinTimeOut;

const Video = () => {
  const {
    stepCovered,
    setStepCovered,
    setScreen,
    currentBlock,
    condition,
    setCondition,
    config,
    token,
    mandatory,
    setMandatory,
    inspectionId,
    setStopPing,
    setCurrentPage,
    stopPing,
    VINDeteted,
    setVINDeteted,
  } = useContext(AppContext);

  const arrowUpText = useRef();
  const arrowDownText = useRef();
  const upArrow = useRef();
  const downArrow = useRef();
  const timerRef = useRef();
  const textRef = useRef();
  const videoRef = useRef();
  const startBtn = useRef();
  const stopBtn = useRef();
  const canvasRef = useRef();
  const canvas1 = useRef();
  const canvas4 = useRef();
  const canvas2 = useRef();
  const screen3 = useRef();
  const canvas5 = useRef();
  const reelsRef = useRef();
  const modalRef = useRef();
  const modalImg = useRef();
  const settingsRef = useRef();
  const cardRef = useRef();
  const reviewRef = useRef();
  const warnRef = useRef();
  const backRef = useRef();
  const imgRef = useRef();
  const startRef = useRef();
  const completeRef = useRef();
  const incompleteRef = useRef();
  const completeImgRef = useRef();
  const stopRef = useRef();
  const timeUpRef = useRef();
  const angleRef = useRef();
  const angleDetailRef = useRef();
  const lowLightRegion = useRef();
  const lowLightRegion2 = useRef();
  const hRef = useRef();
  const hRef2 = useRef();
  const vinVideoPopup = useRef();
  const redLineVINcontainer = useRef();
  const VINIndicator = useRef();
  const VINResult = useRef();
  const partDetector = new PartDetector();
  const guidance = new Guidance(config["video-page"]["guidanceMessages"]);

  data = {
    text: config["video-page"]["dataText"],
    feedbacks: [],
    regionsCovered: guidance.regionsCoveredMap,
    angle: 0,
    region: "",
    nudges: { partToPoint: "", Nudge: "" },
  };

  const constraints = {
    video: Object.keys(config).includes("aspectRatio")
      ? {
          width: Object.keys(config).includes("resolution")
            ? config["resolution"]["width"]
            : 1280,
          height: Object.keys(config).includes("resolution")
            ? config["resolution"]["height"]
            : 720,
          facingMode: "environment",
          aspectRatio: 4 / 3,
          zoom: {
            ideal: Object.keys(config).includes("zoom")
              ? config["zoom"]
                ? 0.5
                : 1
              : 0.5,
          },
        }
      : {
          width: Object.keys(config).includes("resolution")
            ? config["resolution"]["width"]
            : window.innerWidth,
          height: Object.keys(config).includes("resolution")
            ? config["resolution"]["height"]
            : window.innerHeight,
          facingMode: "environment",
          zoom: {
            ideal: Object.keys(config).includes("zoom")
              ? config["zoom"]
                ? 0.5
                : 1
              : 0.5,
          },
        },
    audio: false,
  };
  //const constraints = { video: { width: { exact: 1920 }, height: { exact: 1080 }, facingMode: "environment" }, audio: false }

  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf("android") > -1;
  const isIos = !!/iPhone|iPad|iPod/i.test(ua);
  const isChrome = !!window.chrome && ua.indexOf("chrome") > -1;
  const isFirefox = ua.indexOf("firefox") > -1;
  const isSafari = /^((?!chrome|android).)*safari/i.test(ua);

  let webCamPromise = null;
  let model = null;
  let active = true;
  let images = [];
  let endTimer = 0;
  let modalFlag = false;
  let startTimer = null;
  let allRegionsCovered = false;
  let allGreen = false;

  let detections = [];

  data = {
    text: config["video-page"]["dataText"],
    feedbacks: [],
    regionsCovered: guidance.regionsCoveredMap,
    angle: 0,
    region: "",
    nudges: { partToPoint: "", Nudge: "" },
  };

  const isLandscape = () => window.innerWidth > window.innerHeight * 1.2;
  if (logbugs) {
    patLogs("Entered to Video module", inspectionId);
    userLogs({ position: 8, last_page: "video module", inspectionId });
    // ReactGA.send({
    //   hitType: "pageview",
    //   page: "Video module",
    //   title: "Video module",
    // });
  }
  logbugs = false;

  const sendLogData = async (data) => {
    userLogs(data);
  };

  const drawProgress = () => {
    let bar = new progressBar.Circle("#progress", {
      strokeWidth: 6,
      easing: "easeInOut",
      duration: 6000,
      color: "#000000",
      trailColor: "#eee",
      trailWidth: 4,
      svgStyle: null,
      step: function (state, circle) {
        let value = Math.round(circle.value() * 100);
        if (value === 0) {
          circle.setText("");
        } else {
          circle.setText(`${value}%`);
        }
      },
    });

    return bar;
  };

  const captureImg = () => {
    const cnv = canvasRef.current;
    const vw = videoRef.current;
    cnv.width = vw.videoWidth;
    cnv.height = vw.videoHeight;
    cnv.getContext("2d").drawImage(vw, 0, 0);
    let imageColorData = cnv
      .getContext("2d")
      .getImageData(0, 0, cnv.width, cnv.height);
    let blackPixelCount = 0;
    for (var i = 0; i < imageColorData.data.length; i += 4) {
      let brightness =
        (imageColorData.data[i] +
          imageColorData.data[i + 1] +
          imageColorData.data[i + 2]) /
        3;
      if (brightness < 20) {
        // Adjust threshold as needed
        blackPixelCount++;
      }
    }
    let blackPercentage = (blackPixelCount / (cnv.width * cnv.height)) * 100;
    console.log("blackPercentage", blackPercentage);
    console.log("blackPercentage", typeof blackPercentage);
    if (blackPercentage < 100) {
      let canvasFrameEncoded = cnv.toDataURL("image/jpeg");
      let frameData = guidance.getFramesToPush(canvasFrameEncoded, detections);
      currentUploadedPartCount = frameData["currentUploadedPartCount"];
      let frames_to_push = [];
      if (frameData["framesToPush"].length > 1) {
        // if (Object.keys(config).includes("frameTesting")) {
        //   if (config["frameTesting"]) {
        frames_to_push = frameData["framesToPush"][1];
        //   } else {
        //     frames_to_push = frameData["framesToPush"][0];
        //   }
        // } else {
        //   frames_to_push = frameData["framesToPush"][0];
        // }
      }
      let region = Object.keys(config["video-page"]).includes("clientRegion")
        ? config["video-page"]["clientRegion"]
        : "eu";
      for (let i = 0; i < frames_to_push.length; i++) {
        console.log(typeof frames_to_push[i]);
        console.log(frames_to_push[i]);

        if (
          frames_to_push[i] &&
          frames_to_push[i].hasOwnProperty("frameEncoded")
        ) {
          let frameEncoded = frames_to_push[i]["frameEncoded"];
          let angle = frames_to_push[i]["angle"];
          let web_region = frames_to_push[i]["region"];
          let videoData = frames_to_push[i];
          delete videoData.frameEncoded;
          addImageToList({
            name: `${captured}.jpg`,
            imgData: frameEncoded,
            tag: data.region,
            angle,
            web_region,
            region: region,
            videoData: JSON.stringify(frames_to_push[i]),
            dialStatus: finalRegionsMap,
          });
          captured++;
        }
        // else {
        //   console.log("frames_to_push[i] in video js after find undefined value",frames_to_push[i])
        // }
      }
    }
  };

  const detect360 = (
    data,
    regionsAndAnglesMap,
    regionsAngleCountThresh,
    angleStop
  ) => {
    regionsAndAnglesMap[data.region].add(data.angle);
    !angleDetectedGyro.includes(data.angle) &&
      angleDetectedGyro.push(data.angle);
    if (angleStop) {
      let regionAngleCountFlag = true;
      for (let val of Object.values(regionsAndAnglesMap)) {
        if (val.size < regionsAngleCountThresh) regionAngleCountFlag = false;
      }

      if (regionAngleCountFlag) {
        angleStop = false;
        angleRef.current.style.display = "flex";
        guidance.pausePartCoveringAndFrameUpload = true;
        if (showOnce) {
          lowLight();
          intervalId = setInterval(() => {
            lowLight();
          }, 2000);
          Object.keys(data.regionsCovered).map((e) => {
            if (
              data.regionsCovered[e] ===
                constants.REGION_COVERAGE_STATUS.NOT_COVERED ||
              data.regionsCovered[e] ===
                constants.REGION_COVERAGE_STATUS.PARTIALLY_COVERED
            ) {
              if (Object.keys(config["video-page"]).includes("region")) {
                notCoveredArea = ` ${config["video-page"]["region"][e]}, ${notCoveredArea}`;
              } else {
                notCoveredArea = ` ${e}, ${notCoveredArea}`;
              }
            }
          });

          showOnce = false;
          if (notCoveredArea.endsWith(", ")) {
            notCoveredArea = notCoveredArea.slice(0, -2);
          }
          angleDetailRef.current.innerHTML = notCoveredArea;
          drawArc(canvas4.current, data.angle, data.regionsCovered);
          patLogs("One revolution of car is completed", inspectionId);
        }
      }
    }
  };

  const detectFrame = (video, model) => {
    if (stopMlModel) {
      if (allRegionsCovered) {
        guidance.pausePartCoveringAndFrameUpload = true;
      } else if (minutes === 7) {
        guidance.pausePartCoveringAndFrameUpload = true;
      } else if (
        angleRef.current.style.display === "flex" ||
        stopRef.current.style.display === "flex"
      ) {
        guidance.pausePartCoveringAndFrameUpload = true;
      } else if (
        angleRef.current.style.display === "none" ||
        stopRef.current.style.display === "none"
      ) {
        guidance.pausePartCoveringAndFrameUpload = false;
      }
      tf.engine().startScope();
      const processedFrame = partDetector.processInput(video);
      console.log(
        "processedFrame`````````````````````````````",
        processedFrame
      );
      //add logs
      if (detectMlCheck) {
        detectMlCheck = false;
        patLogs("part_detection_is_working", inspectionId);
      }
      //const processedFrame = partDetector.processInput2(video);
      //const processedFrame = partDetector.processInput2Recreate(video);
      //const [processedFrame, originalFrame] = partDetector.processInputReturnOrig(video);

      model.executeAsync(processedFrame).then((predictions) => {
        detections = partDetector.getDetectionObjects(predictions);

        let [
          currentAngle,
          currentRegion,
          regionsCoveredMap,
          feedbacks,
          videoFreezeStatus,
          currentNudge,
        ] = guidance.performChecksAndGetWarningMessage(
          detections,
          processedFrame
        );
        allRegionsCovered = guidance.checkIfAllRegionsCovered();
        finalRegionsMap = regionsCoveredMap;

        data = {
          text: feedbacks[feedbacks.length - 1],
          feedbacks: feedbacks,
          regionsCovered: regionsCoveredMap,
          angle: currentAngle !== -1 ? currentAngle : data.angle,
          region: currentRegion,
          nudges: currentNudge,
        };
        drawArc(canvas1.current, data.angle, data.regionsCovered);
        actualGuidanceRegion = guidance.actualPartsCoveredMap;
        console.log(currentNudge);

        if (Object.keys(config["video-page"]).includes("videoNudges")) {
          if (currentNudge["Nudge"] === "UP") {
            upArrow.current.style.display = "flex";
            arrowUpText.current.innerHTML = Object.keys(
              config["video-page"]["videoNudges"]
            ).includes(currentNudge["partToPoint"].trim())
              ? config["video-page"]["videoNudges"][
                  currentNudge["partToPoint"].trim()
                ]
              : currentNudge["partToPoint"];
            console.log("currentNudgeUP", currentNudge);
            if (stopShowingNudgesUP) {
              setTimeout(() => {
                upArrow.current.style.display = "none";
                stopShowingNudgesUP = true;
              }, 2000);
            }
            stopShowingNudgesUP = false;
          } else if (currentNudge["Nudge"] === "DOWN") {
            downArrow.current.style.display = "flex";
            arrowDownText.current.innerHTML = Object.keys(
              config["video-page"]["videoNudges"]
            ).includes(currentNudge["partToPoint"].trim())
              ? config["video-page"]["videoNudges"][
                  currentNudge["partToPoint"].trim()
                ]
              : currentNudge["partToPoint"];
            console.log("currentNudgeDown", currentNudge);
            if (stopShowingNudgesDOWN) {
              setTimeout(() => {
                downArrow.current.style.display = "none";
                stopShowingNudgesDOWN = true;
              }, 2000);
            }
            stopShowingNudgesDOWN = false;
          }
        }
        // console.log(actualGuidanceRegion)
        detect360(
          data,
          regionsAndAnglesMap,
          regionsAngleCountThresh,
          angleStop
        );
        Object.keys(actualGuidanceRegion).map((e) => {
          allActualRegionDataLog.key = e;
          let takeOutValue = [];
          actualGuidanceRegion[e].forEach((i) => {
            takeOutValue.push(i);
          });
          allActualRegionDataLog[e] = takeOutValue;
        });

        if (
          JSON.stringify(allActualRegionDataLog) !==
          JSON.stringify(allActualRegionDataLogsCheck)
        ) {
          allActualRegionDataLog["VideoTimer"] = `${minutes} : ${seconds}`;
          allActualRegionDataLog["angleDetected"] = angleDetectedGyro;
          allActualRegionDataLog["DialStatus"] = finalRegionsMap;
          userLogs({
            position: 3,
            video_data: JSON.stringify(allActualRegionDataLog),
            inspectionId,
          });
          allActualRegionDataLogsCheck = allActualRegionDataLog;
        }

        if (allRegionsCovered) {
          angleStop = false;
          angleRef.current.style.display = "none";
          setTimeout(() => {
            if (flowStop) return;
            if (minStop) return;
            // document.getElementById("window-arc2").style.display = "none"
            if (greenStop) {
              lowLightImageData = [];
              stopRef.current.style.display = "flex";
              lowLight();
              drawArc(canvas5.current, data.angle, data.regionsCovered);
              intervalId2 = setInterval(() => {
                lowLight();
              }, 2000);
            }
            greenStop = false;
            allGreen = true;
            guidance.pausePartCoveringAndFrameUpload = true;
          }, 3000);
        }

        if (!guidance.pausePartCoveringAndFrameUpload) captureImg();

        if (active) requestAnimationFrame(() => detectFrame(video, model));

        tf.engine().endScope();
      });
    } else {
      updateUI();
    }
  };

  const lowLightCalculation = (data) => {
    Object.keys(data).forEach((key) => {
      if (data[key].length > lumaImageCountThreshold) {
        const countOccurrences = (arr, value) =>
          arr.filter((element) => element === value).length;
        const lowLightData = valuesToCountLowLightImage
          .map((value) => countOccurrences(data[key], value))
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        if (lowLightData / data[key].length > lowLightThreshold) {
          if (Object.keys(config["video-page"]).includes("region")) {
            if (Object.keys(config["video-page"]["region"]).includes(key)) {
              !lowLightImageData.includes(
                config["video-page"]["region"][key]
              ) &&
                lowLightImageData.push(
                  `${config["video-page"]["region"][key]}`
                );
            } else {
              !lowLightImageData.includes(key) &&
                lowLightImageData.push(`${key}`);
            }
          } else {
            !lowLightImageData.includes(key) &&
              lowLightImageData.push(`${key}`);
          }
        }
      }
    });
    return lowLightImageData;
  };

  const lowLight = async () => {
    hRef.current.style.display = "none";
    hRef2.current.style.display = "none";
    let region = "";
    let data = await lowLightImage();
    WholeLightLumaData = data;
    let value = lowLightCalculation(data);
    // console.log(value)
    if (value.length > 0) {
      value.map((e) => {
        region = `${e}, ${region}`;
      });
      region.endsWith(", ") && (region = region.slice(0, -2));
      // console.log(region, typeof region)
      // region = region + JSON.stringify(data)
      // hRef.current.style.display = "block"
      // hRef2.current.style.display = "block"
      // lowLightRegion.current.innerHTML = region
      // lowLightRegion2.current.innerHTML = region
    } else {
      // region = region + JSON.stringify(data)
      // lowLightRegion.current.innerHTML = region
      // lowLightRegion2.current.innerHTML = region
      hRef.current.style.display = "none";
      hRef2.current.style.display = "none";
    }
  };

  const updateUI = () => {
    if (stopMlModel) {
      textRef.current.style.display = "block";
      textRef.current.innerHTML = data.text
        ? data.text
        : "<span>&nbsp;&nbsp;</span>";
      // textRef.current.innerHTML = `${guidance.pausePartCoveringAndFrameUpload} , ${angleStop}`
    } else {
      textRef.current.innerHTML = Object.keys(config).includes("vin-video")
        ? config["vin-video"]["instruction"]
        : "Please place the VIN inside the white-bordered box until you receive a pop-up message";
    }

    if (!isLandscape()) {
      warnRef.current.style.display = "flex";
    } else {
      warnRef.current.style.display = "none";
    }
    if (seconds >= 60) {
      minutes++;
      seconds = 0;
    }
    if (seconds < 10) {
      timerRef.current.innerText = "0" + minutes + ":" + "0" + seconds;
    } else {
      timerRef.current.innerText = "0" + minutes + ":" + seconds;
    }
    if (minutes === 7 && !processCompleted) {
      if (
        Object.keys(config["video-page"]).includes("videoTimeoutRefreshLink")
      ) {
        stopBtn.current.style.display = "none";
      }
      angleStop = false;
      angleRef.current.style.display = "none";
      minStop = true;
      guidance.pausePartCoveringAndFrameUpload = true;
      stopRef.current.style.display = "none";
      timeUpRef.current.style.display = "flex";
      document.getElementById("window-arc2").style.display = "none";
    }
    timerRef.current.style.fontSize = "larger";
    timerRef.current.style.color = "white";
    timerRef.current.style.marginTop = "40px";
    timerRef.current.style.backgroundColor = "rgba(0,0,0,.7)";

    // console.log(canvas1.current, data.angle, data.regionsCovered)
    if (data.videoFreezeStatus) {
      active = false;
      modalFlag = true;
      drawArc(canvas2.current, data.angle, data.regionsCovered);
    }
  };

  const reShoot = () => {
    startCamera();
  };

  const continueShoot = () => {
    modalFlag = false;
    active = true;
    detectFrame(videoRef.current, model);
  };

  const startCamera = () => {
    patLogs("camera button clicked", inspectionId);
    angleRef.current.style.display = "none";
    timeUpRef.current.style.display = "none";
    document.getElementById("window-arc2").style.visibility = "hidden";
    document.getElementById("btn-car-container").style.visibility = "hidden";
    cardRef.current.style.display = "none";
    backRef.current.style.display = "none";
    startRef.current.style.display = "none";
    textRef.current.style.display = "none";
    reviewRef.current.style.display = "none";
    warnRef.current.style.display = "none";
    imgRef.current.style.display = "block";
    stopRef.current.style.display = "none";
    drawArc(canvas1.current, data.angle, data.regionsCovered);
    // settingsRef.current.innerHTML = config["video-page"]["settingsText"];
    // settingsRef.current.style.color = "black";
    // settingsRef.current.style.marginTop = "60px";
    // settingsRef.current.style.padding = "25px";
    webCamPromise = navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        window.stream = stream;
        track = stream.getTracks()[0];
        videoRef.current.srcObject = stream;
        startBtn.current.style.display = "block";
        backRef.current.style.display = "block";
        textRef.current.style.display = "block";
        document.getElementById("btn-car-container").style.visibility =
          "visible";
        document.getElementById("window-arc2").style.visibility = "visible";
        settingsRef.current.style.display = "none";
        startRef.current.style.display = "flex";
        // if(screenfull.isEnabled) screenfull.request(screen3.current, {navigationUI: 'hide'})
        return new Promise(
          (resolve, _) => (videoRef.current.onloadedmetadata = () => resolve())
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const startRecording = () => {
    textRef.current.style.display = "block";
    capture_VIN_mages(false);
    sendLogData({ position: 9, record_button: "yes", inspectionId });
    if (isIos || isSafari) DeviceMotionEvent.requestPermission();
    startBtn.current.style.display = "none";
    startRef.current.style.display = "none";
    backRef.current.style.display = "none";
    cardRef.current.style.display = "flex";
    stopRef.current.style.display = "none";
    drawProgress().animate(0.9);
    settingsRef.current.style.display = "none";
    timerRef.current.style.width = "10%";
    timerRef.current.style.marginLeft = "45%";
    Promise.all([getModel(), webCamPromise])
      .then((values) => {
        sendLogData({
          position: 6,
          model_initialized: "yes",
          inspectionId,
        });
        model = values[0];
        detectFrame(videoRef.current, values[0]);
        stopBtn.current.style.display = "block";
        startTimer = setInterval(() => {
          cardRef.current.style.display = "none";
          seconds++;
          endTimer++;
          requestAnimationFrame(() => updateUI());
        }, 1000);
        setCurrentPage("Model initialize");
      })
      .catch((error) => {
        console.error(error);
        sendLogData({ position: 6, model_initialized: "no", inspectionId });
        setCurrentPage("Model not initialize");
        // ReactGA.event({
        //   category: "Model not initialize",
        //   action: "Model not initialize",
        // });
      });
  };

  const stopRecording = async (clicked) => {
    patLogs("stop recording clicked", inspectionId);
    // console.log("finalRegionsMap", finalRegionsMap, "lowLightImageData", WholeLightLumaData)
    console.log("actualGuidanceRegion", actualGuidanceRegion);
    Object.keys(actualGuidanceRegion).map((e) => {
      allActualRegionData.key = e;
      let takeOutValue = [];
      actualGuidanceRegion[e].forEach((i) => {
        takeOutValue.push(i);
      });
      allActualRegionData[e] = takeOutValue;
    });
    allActualRegionData["VideoTimer"] = `${minutes} : ${seconds}`;
    allActualRegionData["angleDetected"] = angleDetectedGyro;
    allActualRegionData["lowLightImage"] = WholeLightLumaData;
    allActualRegionData["DialStatus"] = finalRegionsMap;
    allActualRegionData["currentUploadedPartCount"] = currentUploadedPartCount;
    console.log(allActualRegionData, inspectionId);
    console.log("angleDetectedGyro", angleDetectedGyro);
    actualRegion(allActualRegionData, inspectionId);
    // console.log(`video Data -> ${JSON.stringify(allActualRegionData)}`)
    processCompleted = true;
    timeUpRef.current.style.display = "none";
    cardRef.current.style.display = "none";
    warnRef.current.style.display = "none";
    stopBtn.current.style.display = "none";
    textRef.current.style.display = "none";
    settingsRef.current.style.display = "none";
    if (clicked) {
      if (allRegionsCovered) {
        incompleteRef.current.style.display = "none";
        document.getElementById("window-arc2").style.display = "none";
        completeRef.current.style.display = "flex";
        completeImgRef.current.style.display = "flex";
        allGreen = true;
      } else {
        completeRef.current.style.display = "none";
        completeImgRef.current.style.display = "none";
        document.getElementById("window-arc2").style.display = "flex";
        document.getElementById("window-arc2").style.top = "38%";
        document.getElementById("window-arc2").style.left = "40%";
        document.getElementById("window-arc2").style.zIndex = "4";
        document.getElementById("window-arc2").style.height = "10%";
        document.getElementById("window-arc2").style.width = "10%";
        allGreen = false;
      }
    }

    document.getElementById("btn-car-container").style.visibility = "hidden";
    reviewRef.current.style.display = "flex";

    active = false;
    delete allActualRegionData.lowLightImage;
    userLogs({
      position: 3,
      video_data: JSON.stringify(allActualRegionData),
      inspectionId,
    });
  };

  const showModal = (e) => {
    modalImg.current.src = images[e.target.dataset.index].imgData;
    modalRef.current.classList.add("active");
  };

  const hideModal = () => {
    modalRef.current.classList.remove("active");
  };

  const back = () => {
    logbugs = true;
    window.stream = null;
    track.stop();
    patLogs("clicked back button", inspectionId);
    setScreen("menu");
  };

  const handleNext = () => {
    clearInterval(startTimer);
    window.stream = null;
    track.stop();
    logbugs = false;
    patLogs("video module is completed", inspectionId);
    sendCoveredArea(allGreen, finalRegionsMap);
    setTimeout(() => {
      if (currentBlock["mandatory"] && captured > 0) {
        let temp1 = mandatory;
        temp1[currentBlock["id"]] = "completed";
        setMandatory(temp1);
      }
      if (captured > 0) {
        let temp = condition;
        temp[currentBlock["id"]] = "completed";
        setCondition(temp);
        setLog(`${currentBlock["tag"]} was captured`);
      }
      setScreen("menu");
    }, 1000);
  };

  const capture_VIN_mages = async (v) => {
    const cnv = canvasRef.current;
    const vw = videoRef.current;
    cnv.width = vw.videoWidth;
    cnv.height = vw.videoHeight;
    cnv.getContext("2d").drawImage(vw, 0, 0);
    let imageColorData = cnv
      .getContext("2d")
      .getImageData(0, 0, cnv.width, cnv.height);
    let blackPixelCount = 0;
    for (var i = 0; i < imageColorData.data.length; i += 4) {
      let brightness =
        (imageColorData.data[i] +
          imageColorData.data[i + 1] +
          imageColorData.data[i + 2]) /
        3;
      if (brightness < 20) {
        // Adjust threshold as needed
        blackPixelCount++;
      }
    }
    let blackPercentage = (blackPixelCount / (cnv.width * cnv.height)) * 100;
    console.log("blackPercentage", blackPercentage);
    console.log("blackPercentage", typeof blackPercentage);
    if (blackPercentage < 100) {
      let canvasFrameEncoded = cnv.toDataURL("image/jpeg");
      let region = Object.keys(config["video-page"]).includes("clientRegion")
        ? config["video-page"]["clientRegion"]
        : "eu";
      const value = await vin_detection({
        image: canvasFrameEncoded.replace(/^data:image\/jpeg;base64,/, ""),
        region: region === "eu" ? "eu-central-1" : "ap-south-1",
      });
      if (v && value && VINDeteted === false) {
        redLineVINcontainer.current.style.display = "none";
        VINResult.current.style.display = "flex";
        setVINDeteted(true);
        clearInterval(vinTimeOut);
      }
      v &&
        addImageToList({
          name: `${"VIN"}.jpg`,
          imgData: cnv.toDataURL("image/jpeg"),
          tag: "VIN",
          web_region: null,
          angle: null,
          region: region,
          videoData: null,
          dialStatus: null,
        });
    }
  };

  const sendVinImages = () => {
    vinTimeOut = setInterval(() => {
      capture_VIN_mages(true);
    }, 2000);
  };

  useEffect(() => {
    vinVideoPopup.current.style.display = "none";
    redLineVINcontainer.current.style.display = "none";
    VINIndicator.current.style.display = "none";
    VINResult.current.style.display = "none";
    setStopPing(null);
    startCamera();
  }, []);

  return (
    <div id="screen3" ref={screen3} style={{ position: "relative" }}>
      <canvas
        ref={canvasRef}
        id="ios-canvas"
        style={
          Object.keys(config).includes("aspectRatio")
            ? {
                width: "100vw",
                height: "100vh",
                objectFit: "contain",
                backgroundColor: "black",
                zIndex: -1,
              }
            : { zIndex: -1 }
        }
      ></canvas>
      <video
        ref={videoRef}
        id="videoWindow"
        style={
          Object.keys(config).includes("aspectRatio")
            ? {
                width: "100vw",
                height: "100vh",
                objectFit: "contain",
                backgroundColor: "black",
                zIndex: -1,
              }
            : { zIndex: -1 }
        }
        autoPlay
        playsInline
      ></video>
      <p ref={timerRef} id="video-instructions"></p>
      <p
        style={{ display: "none" }}
        ref={settingsRef}
        id="video-instructions"
      ></p>
      <div id="btn-car-container"></div>
      <div ref={backRef} className="back-btn" style={{ color: "white" }}>
        <i class="fas fa-arrow-left fa-2x" onClick={back}></i>
      </div>
      <div
        className="VINIndicator"
        style={
          VINDeteted ? { backgroundColor: "green" } : { backgroundColor: "red" }
        }
        ref={VINIndicator}
      >
        <h1>VIN</h1>
      </div>
      <div ref={startBtn} onClick={startRecording} id="btn-android-start"></div>
      <div ref={upArrow} className="upArrow">
        <img src={arrowUp} />
        <p>
          {Object.keys(config["video-page"]).includes("videoNudges")
            ? config["video-page"]["videoNudges"]["Point your camera to"]
            : "Point your camera to"}{" "}
          <span ref={arrowUpText}></span>
        </p>
      </div>
      <div ref={downArrow} className="downArrow">
        <img src={arrowDown} />
        <p>
          {Object.keys(config["video-page"]).includes("videoNudges")
            ? config["video-page"]["videoNudges"]["Point your camera to"]
            : "Point your camera to"}{" "}
          <span ref={arrowDownText}></span>
        </p>
      </div>
      <div
        ref={stopBtn}
        onClick={() => stopRecording(true)}
        id="btn-android-stop"
      ></div>
      <div id="window-arc2">
        <img src={carsmall} alt="" className="car" />
        <canvas ref={canvas1} width="100" height="100"></canvas>
      </div>

      <div className="allgreenstop1" ref={stopRef}>
        <div className="pre-start-card" style={{ width: "40%", height: "70%" }}>
          <strong style={{ transform: "rotate(-90deg)" }}>
            {config["video-page"]["left-text"]}
          </strong>
          <div className="car-flex">
            <strong>{config["video-page"]["front-text"]}</strong>
            <canvas ref={canvas5} width="150" height="150"></canvas>
            <strong>{config["video-page"]["rear-text"]}</strong>
          </div>
          <strong style={{ transform: "rotate(90deg)" }}>
            {config["video-page"]["right-text"]}
          </strong>
          <div style={{ width: "20px" }}></div>
        </div>
        <div className="allgreenstop_bodyDiv">
          <h3 style={{ width: "90%" }}>{config["video-page"]["stopText"]}</h3>
          {/* <h4 style={{ margin: 0 }} ref={hRef}>
            {Object.keys(config["video-page"]).includes("lowLightRegion")
              ? config["video-page"]["lowLightRegion"]
              : "Regions in Low Light:"}{" "}
            <span
              ref={lowLightRegion}
              style={{ margin: 0, fontWeight: 100 }}
            ></span>
          </h4> */}
          <div className="modal-btn-container" style={{ display: "flex" }}>
            <Button
              secoundaryBtn="true"
              text={config["video-page"]["stopRecordingText"]}
              onclick={() => {
                stopRecording(true);
                clearInterval(intervalId2);
              }}
            />
            <Button
              text={config["video-page"]["continueText"]}
              onclick={() => {
                guidance.pausePartCoveringAndFrameUpload = false;
                guidance.stage = 2;
                stopRef.current.style.display = "none";
                flowStop = true;
                clearInterval(intervalId2);
              }}
            />
          </div>
        </div>
      </div>

      {/* <div className="allgreenstop" ref={stopRef}>
        <h3>{config["video-page"]["stopText"]}</h3>
        <h4 style={{ margin: 0 }} ref={hRef}>
          {Object.keys(config["video-page"]).includes("lowLightRegion")
            ? config["video-page"]["lowLightRegion"]
            : "Regions in Low Light:"}{" "}
          <span
            ref={lowLightRegion}
            style={{ margin: 0, fontWeight: 100 }}
          ></span>
        </h4>
        <div className="modal-btn-container" style={{ display: "flex" }}>
          <Button
            text={config["video-page"]["stopRecordingText"]}
            onclick={() => {
              stopRecording(true);
              clearInterval(intervalId2);
            }}
          />
          <Button
            text={config["video-page"]["continueText"]}
            onclick={() => {
              guidance.pausePartCoveringAndFrameUpload = false;
              guidance.stage = 2;
              stopRef.current.style.display = "none";
              flowStop = true;
              clearInterval(intervalId2);
            }}
          />
        </div>
      </div> */}

      <div className="allgreenstop3" ref={timeUpRef}>
        <div className="allgreenstop3_title">
          <h2>
            {Object.keys(config["video-page"]).includes("timeup-text")
              ? config["video-page"]["timeup-text"]
              : "Video can only be seven minutes long."}
          </h2>
        </div>

        {Object.keys(config["video-page"]).includes(
          "videoTimeoutRefreshLink"
        ) && (
          <>
            <div className="allgreenstop3_body">
              <h4 style={{ fontWeight: "600", margin: "0px", padding: "0px" }}>
                {Object.keys(
                  config["video-page"]["videoTimeoutRefreshLink"]
                ).includes("time-limitup-text-1")
                  ? config["video-page"]["videoTimeoutRefreshLink"][
                      "time-limitup-text-1"
                    ]
                  : "Due to our strict 7-minute policy, all videos and photos captured so far will be deleted."}{" "}
                <span style={{ color: "red" }}>
                  {Object.keys(
                    config["video-page"]["videoTimeoutRefreshLink"]
                  ).includes("time-limitup-text-2")
                    ? config["video-page"]["videoTimeoutRefreshLink"][
                        "time-limitup-text-2"
                      ]
                    : "You will need to restart the inspection process."}
                </span>
              </h4>
            </div>
            <div className="allgreenstop3_btn">
              <Button
                text={
                  Object.keys(
                    config["video-page"]["videoTimeoutRefreshLink"]
                  ).includes("restartText_btn")
                    ? config["video-page"]["videoTimeoutRefreshLink"][
                        "restartText_btn"
                      ]
                    : "Restart inspection"
                }
                onclick={() => {
                  location.reload();
                }}
              />
            </div>
          </>
        )}
      </div>

      {/* <div className="allgreenstop2" ref={timeUpRef}>
        <h3>
          {Object.keys(config["video-page"]).includes("timeup-text")
            ? config["video-page"]["timeup-text"]
            : "Video can only be seven minutes long."}
        </h3>
      </div> */}

      {/* <div className="allgreenstop3" ref={timeUpRef}>
        <div className="allgreenstop3_title">
          <h2>
            {Object.keys(config["video-page"]).includes("timeup-text")
              ? config["video-page"]["timeup-text"]
              : "Video can only be seven minutes long."}
          </h2>
        </div>
        {Object.keys(config["video-page"]).includes(
          "videoTimeoutRefreshLink"
        ) && (
            <>
              <div className="allgreenstop3_body">
                <h4 style={{ fontWeight: "600", margin: "0px", padding: "0px" }}>
                  {Object.keys(
                    config["video-page"]["videoTimeoutRefreshLink"]
                  ).includes("time-limitup-text-1")
                    ? config["video-page"]["videoTimeoutRefreshLink"][
                    "time-limitup-text-1"
                    ]
                    : "Due to our strict 7-minute policy, all videos and photos captured so far will be deleted."}{" "}
                  <span style={{ color: "red" }}>
                    {Object.keys(
                      config["video-page"]["videoTimeoutRefreshLink"]
                    ).includes("time-limitup-text-2")
                      ? config["video-page"]["videoTimeoutRefreshLink"][
                      "time-limitup-text-2"
                      ]
                      : "You will need to restart the inspection process."}
                  </span>
                </h4>
              </div>
              <div className="allgreenstop3_btn">
                <Button
                  text={
                    Object.keys(
                      config["video-page"]["videoTimeoutRefreshLink"]
                    ).includes("restartText_btn")
                      ? config["video-page"]["videoTimeoutRefreshLink"][
                      "restartText_btn"
                      ]
                      : "Restart inspection"
                  }
                  onclick={() => {
                    location.reload();
                  }}
                />
              </div>
            </>
          )}
      </div> */}

      <div className="allgreenstop2" ref={angleRef}>
        <div className="pre-start-card" style={{ width: "40%", height: "70%" }}>
          <strong style={{ marginRight: "2px", transform: "rotate(-90deg)" }}>
            {config["video-page"]["left-text"]}
          </strong>
          <div className="car-flex">
            <strong style={{ marginBottom: "8px" }}>
              {config["video-page"]["front-text"]}
            </strong>
            <canvas ref={canvas4} width="150" height="150"></canvas>
            <strong style={{ marginTop: "10px" }}>
              {config["video-page"]["rear-text"]}
            </strong>
          </div>
          <strong style={{ transform: "rotate(90deg)" }}>
            {config["video-page"]["right-text"]}
          </strong>
          <div style={{ width: "20px" }}></div>
        </div>
        <div className="allgreenstop2_bodyDiv">
          <div style={{ width: "90%" }}>
            <h3 style={{ margin: "0px", padding: "0px" }}>
              {Object.keys(config["video-page"]).includes("one-revolution")
                ? config["video-page"]["one-revolution"]
                : "You have completed one revolution around the car."}
            </h3>
          </div>
          <div className="notCoveredAreas">
            <p style={{ fontWeight: "600", margin: "0px", padding: "0px" }}>
              {Object.keys(config["video-page"]).includes("missed-region-text")
                ? config["video-page"]["missed-region-text"]
                : "Continue to capture missed regions:"}{" "}
              <span
                ref={angleDetailRef}
                style={{ margin: 0, fontWeight: 100, color: "red" }}
              ></span>
            </p>
            {/* <p style={{ fontWeight: '300' }} ref={hRef2}>
              {Object.keys(config["video-page"]).includes("lowLightRegion")
                ? config["video-page"]["lowLightRegion"]
                : "Regions in Low Light:"}{" "}
              <span
                ref={lowLightRegion2}
                style={{ margin: 0, fontWeight: 100 }}
              ></span>
            </p> */}
          </div>
          <div className="modal-btn-container">
            <Button
              secoundaryBtn="true"
              text={config["video-page"]["stopRecordingText"]}
              onclick={() => {
                angleStop = false;
                angleRef.current.style.display = "none";
                stopRecording(true);
                clearInterval(intervalId);
              }}
            />
            <Button
              text={config["video-page"]["continueText"]}
              onclick={() => {
                angleStop = false;
                angleRef.current.style.display = "none";
                guidance.pausePartCoveringAndFrameUpload = false;
                guidance.stage = 2;
                clearInterval(intervalId);
              }}
            />
          </div>
        </div>
      </div>

      {/* <div className="allgreenstop2" ref={angleRef}>
        <br></br>
        <h3>
          {Object.keys(config["video-page"]).includes("one-revolution")
            ? config["video-page"]["one-revolution"]
            : "You have completed one revolution around the car."}
        </h3>
        <div className="notCoveredAreas">
          <div>
            <canvas ref={canvas4} width="100" height="100"></canvas>
          </div>
          <div style={{ textAlign: "left", width: "55%" }}>
            <h4 style={{ margin: 0 }}>
              {Object.keys(config["video-page"]).includes("missed-region-text")
                ? config["video-page"]["missed-region-text"]
                : "Continue to capture missed regions:"}{" "}
              <span
                ref={angleDetailRef}
                style={{ margin: 0, fontWeight: 100 }}
              ></span>
            </h4>
            <br></br>
            <h4 style={{ margin: 0 }} ref={hRef2}>
              {Object.keys(config["video-page"]).includes("lowLightRegion")
                ? config["video-page"]["lowLightRegion"]
                : "Regions in Low Light:"}{" "}
              <span
                ref={lowLightRegion2}
                style={{ margin: 0, fontWeight: 100 }}
              ></span>
            </h4>
          </div>
        </div>
        <div className="modal-btn-container" style={{ display: "flex" }}>
          <Button
            text={config["video-page"]["stopRecordingText"]}
            onclick={() => {
              angleStop = false;
              angleRef.current.style.display = "none";
              stopRecording(true);
              clearInterval(intervalId);
            }}
          />
          <Button
            text={config["video-page"]["continueText"]}
            onclick={() => {
              angleStop = false;
              angleRef.current.style.display = "none";
              guidance.pausePartCoveringAndFrameUpload = false;
              guidance.stage = 2;
              clearInterval(intervalId);
            }}
          />
        </div>
      </div> */}

      <div ref={cardRef} id="display-card">
        <div className="pre-start-card">
          <strong style={{ marginRight: "2px", transform: "rotate(-90deg)" }}>
            {config["video-page"]["left-text"]}
          </strong>
          <div className="car-flex">
            <strong style={{ marginBottom: "8px" }}>
              {config["video-page"]["front-text"]}
            </strong>
            <img ref={imgRef} src={initImg}></img>
            <strong style={{ marginTop: "10px" }}>
              {config["video-page"]["rear-text"]}
            </strong>
          </div>
          <strong style={{ transform: "rotate(90deg)" }}>
            {config["video-page"]["right-text"]}
          </strong>
          <div style={{ width: "20px" }}></div>
        </div>
        <div className="card-info">
          <div>
            <div className="car-green"></div>
            <b>{config["video-page"]["covered-text"]}</b>
          </div>
          <div>
            <div className="car-red"></div>
            <b>{config["video-page"]["notcovered-text"]}</b>
          </div>
          <div className="card-note">
            <p style={{ width: "100%" }}>
              {config["video-page"]["loadingText"]}
            </p>
            <div style={{display: "none"}} className="progress" id="progress"></div>
          </div>
        </div>
      </div>

      {/* <div ref={cardRef} id="display-card">
        <h3>{config["video-page"]["loadingText"]}</h3>
        <div className="progress" id="progress"></div>
        <div className="pre-start-card">
          <strong>{config["video-page"]["left-text"]}</strong>
          <div className="car-flex">
            <strong>{config["video-page"]["front-text"]}</strong>
            <img
              ref={imgRef}
              src={initImg}
              style={{ width: "120px", height: "120px" }}
            ></img>
            <strong>{config["video-page"]["rear-text"]}</strong>
          </div>
          <strong>{config["video-page"]["right-text"]}</strong>
          <div style={{ width: "20px" }}></div>
          <div className="car-info">
            <div>
              <div className="car-green"></div>
              <p>{config["video-page"]["covered-text"]}</p>
            </div>
            <div>
              <div className="car-red"></div>
              <p>{config["video-page"]["notcovered-text"]}</p>
            </div>
          </div>
        </div>
      </div> */}

      <div ref={startRef} id="stop-display-card">
        <div className="pre-start-card">
          <strong style={{ marginRight: "2px", transform: "rotate(-90deg)" }}>
            {config["video-page"]["left-text"]}
          </strong>
          <div className="car-flex">
            <strong style={{ marginBottom: "8px" }}>
              {config["video-page"]["front-text"]}
            </strong>
            <img ref={imgRef} src={initImg}></img>
            <strong style={{ marginTop: "10px" }}>
              {config["video-page"]["rear-text"]}
            </strong>
          </div>
          <strong style={{ transform: "rotate(90deg)" }}>
            {config["video-page"]["right-text"]}
          </strong>
          <div style={{ width: "20px" }}></div>
        </div>
        <div className="card-info">
          <div>
            <div className="car-green"></div>
            <b style={{ width: "90%" }}>
              {config["video-page"]["covered-text"]}
            </b>
          </div>
          <div>
            <div className="car-red"></div>
            <b style={{ width: "90%" }}>
              {config["video-page"]["notcovered-text"]}
            </b>
          </div>
          <div className="card-note">
            <p>{config["video-page"]["dataText"]}</p>
          </div>
        </div>
      </div>
      <div>
        <p ref={textRef} id="video-recording-text"></p>
      </div>

      {/* <div ref={startRef} id="stop-display-card">
        <div className="pre-start-card">
          <strong>{config["video-page"]["left-text"]}</strong>
          <div className="car-flex">
            <strong>{config["video-page"]["front-text"]}</strong>
            <img ref={imgRef} src={initImg}></img>
            <strong>{config["video-page"]["rear-text"]}</strong>
          </div>
          <strong>{config["video-page"]["right-text"]}</strong>
          <div style={{ width: "20px" }}></div>
          <div className="car-info">
            <div>
              <div className="car-green"></div>
              <p>{config["video-page"]["covered-text"]}</p>
            </div>
            <div>
              <div className="car-red"></div>
              <p>{config["video-page"]["notcovered-text"]}</p>
            </div>
          </div>
        </div>
        <div>
          <p style={{ textAlign: "center" }}>
            {config["video-page"]["dataText"]}
          </p>
        </div>
      </div>
      <div>
        <p ref={textRef} id="video-recording-text"></p>
      </div> */}

      <div ref={reviewRef} id="review-card">
        <h4 ref={completeRef}>{config["video-page"]["completeText"]}</h4>
        <h4 ref={incompleteRef}>{config["video-page"]["incompleteText"]}</h4>
        <img ref={completeImgRef} src={indicator}></img>
        <div className="review-btns">
          <Button
            text={config["video-page"]["btnText"]}
            onclick={() => {
              stopMlModel = false;
              document.getElementById("window-arc2").style.visibility =
                "hidden";
              reviewRef.current.style.display = "none";
              vinVideoPopup.current.style.display = "flex";
              capture_VIN_mages(false);
            }}
          ></Button>
        </div>
      </div>

      <div className="vinVideoPopup" ref={vinVideoPopup}>
        <div className="vinVideoPopupcontainer">
          <h4>
            {Object.keys(config).includes("vin-video")
              ? config["vin-video"]["locate vin inst."]
              : "Locate the VIN in your car and then press the 'Continue' button to start recording the VIN"}
          </h4>
          <img src={VINimage} style={{ width: "100px", height: "100px" }} />
          <Button
            text={
              Object.keys(config).includes("vin-video")
                ? config["vin-video"]["continue-btn"]
                : "Continue"
            }
            onclick={() => {
              vinVideoPopup.current.style.display = "none";
              sendVinImages();
              textRef.current.innerHTML = textRef.current.innerHTML =
                Object.keys(config).includes("vin-video")
                  ? config["vin-video"]["instruction"]
                  : "Please place the VIN inside the white-bordered box until you receive a pop-up message";
              textRef.current.style.display = "block";
              redLineVINcontainer.current.style.display = "flex";
              VINIndicator.current.style.display = "flex";
              setTimeout(() => {
                clearInterval(vinTimeOut);
                textRef.current.style.display = "none";
                redLineVINcontainer.current.style.display = "none";
                VINResult.current.style.display = "flex";
              }, 10000);
            }}
          ></Button>
        </div>
      </div>

      <div className="redLineVINcontainer" ref={redLineVINcontainer}>
        <div
          className="redLineVIN"
          style={
            Object.keys(config).includes("aspectRatio")
              ? { width: "50vw" }
              : { width: "70vw" }
          }
        ></div>
      </div>

      <div className="VINResult" ref={VINResult}>
        <div className="VINResultContainer">
          <div className="VINResultContainerTxt">
            <div
              className="VINIndicator1"
              style={
                VINDeteted
                  ? { backgroundColor: "green" }
                  : { backgroundColor: "red" }
              }
            >
              <h3>VIN</h3>
            </div>
          </div>
          {VINDeteted ? (
            <h3>
              {Object.keys(config).includes("vin-video")
                ? config["vin-video"]["vinDetected message"]
                : "VIN is Detected"}
            </h3>
          ) : (
            <h3>
              {Object.keys(config).includes("vin-video")
                ? config["vin-video"]["vinNotDetected message"]
                : "VIN is not Detected"}
            </h3>
          )}
          <div className="VINResultContainerbtn">
            {!VINDeteted && (
              <Button
                text={
                  Object.keys(config).includes("vin-video")
                    ? config["vin-video"]["retryBtnText"]
                    : "Retry"
                }
                onclick={() => {
                  textRef.current.style.display = "block";
                  VINResult.current.style.display = "none";
                  sendVinImages();
                  setTimeout(() => {
                    clearInterval(vinTimeOut);
                    textRef.current.style.display = "none";
                    redLineVINcontainer.current.style.display = "none";
                    VINResult.current.style.display = "flex";
                  }, 10000);
                  redLineVINcontainer.current.style.display = "flex";
                }}
              ></Button>
            )}
            <Button
              text={
                Object.keys(config).includes("vin-video")
                  ? config["vin-video"]["contnueExitBtnText"]
                  : "Continue"
              }
              onclick={() => {
                clearInterval(vinTimeOut);
                timerRef.current.style.display = "none";
                handleNext();
              }}
            ></Button>
          </div>
        </div>
      </div>

      <div ref={warnRef} id="warn-card">
        <Rotate />
        <p className="note">{config["video-page"]["rotateText"]}</p>
      </div>

      {modalFlag && (
        <div id="screen3-modal">
          <div className="modal-column">
            <div className="full-center">
              <div>
                <h2 className="header2">
                  You have not taken the Front View properly
                </h2>
                <strong>Problems identified:</strong>
                <ol>
                  {data.feedbacks.map((warning) => (
                    <li key={warning}>{warning}</li>
                  ))}
                </ol>
                <p>
                  Would you like to reshoot from front or continue as it is?
                </p>
                <div className="modal-btn-flex">
                  <Button text="Reshoot" onclick={reShoot} />
                  <Button
                    text="Continue"
                    translucent={true}
                    onclick={continueShoot}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-column">
            <div className="full-center">
              <div>
                <canvas ref={canvas2} width="200" height="200"></canvas>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Video;
