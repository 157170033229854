import React, { Fragment, useContext } from "react";
import Menu from "./components/Menu";
import SingleImage from "./components/SingleImage";
import Damage from "./components/Damage";
import Video from "./components/Video";
import MultipleImage from "./components/MultipleImage";
import Loading from "./components/Loading";
import Instructions from "./components/Instructions";
import Email from "./components/Email";
import Navbar from "./components/Navbar";
import { AppContext } from "./context";
import { useAppData } from "./context/hooks";
import Success from "./components/Success";
import QR from "./components/QR";
import Error from "./components/Error";
import Zeroapp from "./components/Zeroapp";
import WindshieldDamge from "./components/WindshieldDamge";
import Form from "./components/Form";
import Ping from "./components/Ping";
import NewVideo from "./components/NewVideo";
// import * as Sentry from "@sentry/react";
// import ReactGA from "react-ga4";
import Rejection from "./components/Rejection";
import SelfieModule from "./components/SelfieModule";
import VideoRecorder from "./components/VideoRecorder";
import FormModule from "./components/FormModule";
import CustomHiibModule from "./components/CustomHiibModule"
import TimeOut from "./components/TimeOut"

function App() {
  // ReactGA.initialize("G-34Z6GNKVWM");

  const appData = useAppData();
  return (
    // <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <AppContext.Provider value={appData}>
        <Fragment>
          <>
            {appData.screen === "loading" ? (
              <Loading />
            ) : appData.screen === "error" ? (
              <Error />
            ) : appData.screen === "rejection" ? (
              <div>
                <Navbar />
                <Rejection />
              </div>
            ) : appData.screen === "form" ? (
              <div>
                <Navbar />
                <Form />
              </div>
            ) : appData.screen === "email" ? (
                <Email />
            ) : appData.screen === "instructions" ? (
              <div>
                <Instructions />
              </div>
            ) : appData.screen === "menu" ? (
              <Menu />
            ) : appData.screen === "block" ? (
              <SelectPage />
            ) : appData.screen === "success" ? (
              <Success />
            ) : appData.screen === "timeOut" ? (
              <TimeOut />
            ) : (
              <SelectPage />
            )}
            {appData.screen !== "loading" && <Ping />}
          </>
        </Fragment>
      </AppContext.Provider>
    // </Sentry.ErrorBoundary>
  );
}

const SelectPage = () => {
  const { currentBlock } = useContext(AppContext);
  switch (currentBlock.name) {
    case "damage":
      return <Damage />;
    case "Video Capture":
      return <Video />;
    case "QR":
      return <QR />;
    case "New Video Capture":
      return <NewVideo />;
    case "zeroapp":
      return <Zeroapp />;
    case "Windshield Damage":
      return <WindshieldDamge />;
    case "Selfie":
      return <SelfieModule />;
    case "Video Recorder":
      return <VideoRecorder />;
    case "Form Module":
      return <FormModule />;
    case "Custom Hiib Module":
      return <CustomHiibModule />
    default:
      switch (currentBlock.subSections) {
        case true:
          return <MultipleImage />;
        case false:
          return <SingleImage />;
        default:
          return <></>;
      }
  }
};

export default App;
