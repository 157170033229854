import { AbruptMovementDetector } from "./AbruptMovementDetection";
import { AngleRFEstimator } from "./AngleRFEstimation";
import { AngleGyroEstimator } from "./AngleGyroEstimation";
import { DistanceEstimator } from "./DistanceEstimation";
import { HighSpeedDetector } from "./HighSpeedDetection";
import { LowLightDetector } from "./LowLightDetection";
import { OrientationEstimator } from "./OrientationEstimation";
import { PartCoverageEstimator } from "./PartCoverage";
import { Nudges } from "./NudgesFlag";

import * as constants from "../utils_new/Constants";
import * as utilFuncs from "../utils_new/UtilFuncs";

import _ from "underscore";

export class Guidance {
  constructor(messages) {
    // Pause GuidanceModule
    this.pausePartCoveringAndFrameUpload = false;

    // VideoFreeze Variable
    this.videoFreezeStatus = false;

    // Warning Message List
    this.feedbacks = [];

    // Distance Variables
    this.distanceWarningCounter = 0;
    this.distanceWarningCounterThreshold = 20;
    this.distanceSlidingWindow = [];
    this.distanceWindowSize = 5;
    this.aggregatedDistance = false;
    this.zoomedInMessage = messages["zoomedIn"]; //"Please stand 2-3 feet away from the car.";
    this.farAwayMessage = messages["farAway"]; //"You are too far away from the car. Go a bit closer."

    // Detection Variables
    this.noBoundingBoxesCounter = 0;
    this.noBoundingBoxesCounterThreshold = 20;
    this.noDetectionMessage = messages["noDetection"]; //"Please point your camera at the car.";

    // Angle Bbox Variables
    this.angleSlidingWindow = [];
    this.angleWindowSize = 5;
    this.angleCollectionList = [];
    this.angleCollectionListLengthThreshold = 5;
    this.aggregatedAngle = constants.START_FLAG;

    // Orientation Variables
    this.orientationSlidingWindow = [];
    this.orientationWindowSize = 5;
    this.orientationCollectionList = [];
    this.orientationCollectionListLengthThreshold = 5;
    this.aggregatedOrientation = constants.START_FLAG;

    // Speed Variables
    this.highSpeedCounter = 0;
    this.highSpeedCounterThreshold = 3;
    this.showhighSpeedMessageCounter = -1;
    this.showhighSpeedMessageCounterThreshold = 15;
    this.highSpeedMessage = messages["speedHigh"]; //"You are moving too fast! Please slow down.";

    // Abrupt Movement Variables
    this.abruptMovementCounter = 0;
    this.abruptMovementCounterThreshold = 20;
    this.showAbruptMovementWarningMessageCounter = -1;
    this.showAbruptMovementWarningMessageCounterThreshold = 15;
    this.abruptMovementMessage = messages["abruptMovement"]; //"Abrupt Movement detected !";

    // Other Messages
    this.lowLightMessage = messages["lowLight"]; //"Please take the video in proper lighting conditions";
    this.initializingAngleMessage = messages["initializingAngle"]; //"Please point towards the car while the Angle is being calculated.";
    this.genericMessage = messages["generic"]; //"Continue going around the car at a steady pace.";

    // Angle Global Variables
    this.currentAngle = -1;

    // Region Global Variables
    this.currentRegion = constants.REGIONS.FRONT; //constants.START_FLAG;
    this.previousRegion = constants.REGIONS.FRONT; //constants.START_FLAG;

    // Frame Sampling per Region variables
    this.stage = 1;
    this.regionFrameDetails = [];
    this.regionFrameDetailsv2 = [];
    this.nMaxFramesCurrentRegion = 25;
    this.nMaxFramesUploadedPerRegion = 3;
    this.frameSelectionAngleStepThresh = 10;
    this.initialCoverageStatusPreviousRegion = constants.START_FLAG;

    //nudge variable
    this.nContinuousFrameForNudge = 3;
    this.nudgeWindow = [];
    this.currentNudge = { partToPoint: "", Nudge: "NA" };

    //initialize the part count with zero. We will upload only those parts which have part count less than 2
    this.currentUploadedPartCount = {
      back_bumper: 0,
      back_glass: 0,
      dicky: 0,
      front_bumper: 0,
      front_glass: 0,
      hood: 0,
      left_running_board: 0,
      left_window_glass: 0,
      left_back_door: 0,
      left_front_door: 0,
      right_running_board: 0,
      right_window_glass: 0,
      right_back_door: 0,
      right_front_door: 0,
      left_fender: 0,
      right_fender: 0,
      left_qtr_panel: 0,
      right_qtr_panel: 0,
      left_front_bumper_isometric: 0,
      right_front_bumper_isometric: 0,
    };

    // Declare and Initialize the uploadedFramesAngleMap
    this.uploadedFramesAngleMap = {};
    for (let val of Object.values(constants.REGIONS))
      this.uploadedFramesAngleMap[val] = new Set();

    // Declare and Initialize the regionsCoveredMap
    this.regionsCoveredMap = {};
    for (let val of Object.values(constants.REGIONS))
      this.regionsCoveredMap[val] =
        constants.REGION_COVERAGE_STATUS.NOT_COVERED;

    // Declare and Intialize the actualPartsCoveredMap
    this.actualPartsCoveredMap = {};
    for (let val of Object.values(constants.REGIONS))
      this.actualPartsCoveredMap[val] = new Set();

    // Declare and Initialize the referencePartsCoveredMap
    this.referencePartsCoveredMap = {};
    this.referencePartsCoveredMap[constants.REGIONS.FRONT] = new Set([
      "front_bumper",
      "hood",
      "front_glass",
    ]);
    this.referencePartsCoveredMap[constants.REGIONS.FRONT_ISOMETRIC_LEFT] =
      new Set(["left_fender", "left_front_bumper_isometric"]);
    this.referencePartsCoveredMap[constants.REGIONS.FRONT_ISOMETRIC_RIGHT] =
      new Set(["right_fender", "right_front_bumper_isometric"]);
    this.referencePartsCoveredMap[constants.REGIONS.SIDE_LEFT] = new Set([
      "left_front_door",
      "left_back_door",
      "left_window_glass",
      "left_running_board",
    ]);
    this.referencePartsCoveredMap[constants.REGIONS.SIDE_RIGHT] = new Set([
      "right_front_door",
      "right_back_door",
      "right_window_glass",
      "right_running_board",
    ]);
    this.referencePartsCoveredMap[constants.REGIONS.REAR] = new Set([
      "back_bumper",
      "dicky",
      "back_glass",
    ]);
    // this.referencePartsCoveredMap[constants.REGIONS.REAR_ISOMETRIC_LEFT] = new Set(["left_qtr_panel", "left_back_bumper_isometric"]);
    // this.referencePartsCoveredMap[constants.REGIONS.REAR_ISOMETRIC_RIGHT] = new Set(["right_qtr_panel", "right_back_bumper_isometric"]);
    this.referencePartsCoveredMap[constants.REGIONS.REAR_ISOMETRIC_LEFT] =
      new Set(["left_qtr_panel"]);
    this.referencePartsCoveredMap[constants.REGIONS.REAR_ISOMETRIC_RIGHT] =
      new Set(["right_qtr_panel"]);

    // Guidance Modules
    this.abruptMovementDetector = new AbruptMovementDetector();
    this.angleRFEstimator = new AngleRFEstimator();
    this.angleGyroEstimator = new AngleGyroEstimator();
    this.distanceEstimator = new DistanceEstimator();
    this.highSpeedDetector = new HighSpeedDetector();
    this.lowLightDetector = new LowLightDetector();
    this.orientationEstimator = new OrientationEstimator();
    this.partCoverageEstimator = new PartCoverageEstimator();
    this.nudges = new Nudges();
  }

  // Function that performs the Zoom/FarAway checks. If the view is zoomed in/far away for too long, freeze the video.
  performDistanceCheck(boundingBoxDetections) {
    // Check if a frame is Zoomed in/Far away and push the result into the sliding window
    let distanceVal = this.distanceEstimator.checkDistance(
      boundingBoxDetections
    );
    this.distanceSlidingWindow.push(distanceVal);

    // Determine the aggregatedDistance from the mode of the sliding window.
    if (
      this.distanceSlidingWindow.length > 0 &&
      this.distanceSlidingWindow.length % this.distanceWindowSize === 0
    ) {
      this.aggregatedDistance = utilFuncs.getMode(this.distanceSlidingWindow);
      this.distanceSlidingWindow.length = 0;
    }

    // Check aggregatedDistance value and increment the counter.
    if (this.aggregatedDistance) {
      this.distanceWarningCounter++;
      if (this.aggregatedDistance === 1)
        this.feedbacks.push(this.zoomedInMessage);
      if (this.aggregatedDistance === 2)
        this.feedbacks.push(this.farAwayMessage);
    } else this.distanceWarningCounter = 0;

    // Compare counter against threshold to determine if video needs to be frozen or not.
    if (this.distanceWarningCounter >= this.distanceWarningCounterThreshold)
      this.videoFreezeStatus = true;
  }

  /* If there are no Detections (and hence Bboxes) for a lot of consecutive frames, it is probably 
        because the frames are dark (low-light) or Non-Car object is in view. */
  performDetectionCheck(boundingBoxDetections, processedFrame) {
    // If no detections, increment the counter and push a warning.
    if (boundingBoxDetections.length === 0) {
      this.noBoundingBoxesCounter++;
      if (this.noBoundingBoxesCounter > 5) {
        // Push Low Light warning
        if (this.lowLightDetector.checkLowLight2Trimmed(processedFrame))
          this.feedbacks.push(this.lowLightMessage);
        // Push Non-car object in View warning
        else this.feedbacks.push(this.noDetectionMessage);
      }
    } else this.noBoundingBoxesCounter = 0;

    // Compare counter against threhold to determine if video needs to be frozen or not.
    if (this.noBoundingBoxesCounter >= this.noBoundingBoxesCounterThreshold)
      this.videoFreezeStatus = true;
  }

  /* Calculates the current angle, orientation of the frame and pushes them onto their respective sliding windows.
       Also, updates the Aggregated Angle and Orientation every few frames */
  currentAngleFromBbox(boundingBoxDetections) {
    // Get the Angle (0-180deg) and Orientation of the current frame.
    let angleOfFrame = this.angleRFEstimator.getAngleOfFrame(
      boundingBoxDetections
    );
    // console.log("angleOfFrame",  angleOfFrame)
    let orientationString = this.orientationEstimator.getCarOrientation(
      boundingBoxDetections
    );

    // UNCOMMENT if you want Angle and Orientation to be initialized instantly but it will less accurate
    /*
        // Setting the aggregated Angle and Orientation only for the first frame.
        if (this.aggregatedAngle === constants.START_FLAG) 
            this.aggregatedAngle = angleOfFrame;
        if (this.aggregatedOrientation === constants.START_FLAG) 
            this.aggregatedOrientation = orientationString;
        */

    // Push the Angle and Orientation values into the respective sliding windows.
    this.angleSlidingWindow.push(angleOfFrame);

    this.orientationSlidingWindow.push(orientationString);

    // Calculated the aggregated Angle and Orientation from the respective sliding windows (Not executed for the first frame anyways).
    if (
      this.angleSlidingWindow.length > 0 &&
      this.angleSlidingWindow.length % this.angleWindowSize === 0
    ) {
      // console.log("angleSlidingWindow", this.angleSlidingWindow)
      this.aggregatedAngle = utilFuncs.getMode(this.angleSlidingWindow);
      this.angleSlidingWindow.length = 0;
    }
    if (
      this.orientationSlidingWindow.length > 0 &&
      this.orientationSlidingWindow.length % this.orientationWindowSize === 0
    ) {
      this.aggregatedOrientation = utilFuncs.getMode(
        this.orientationSlidingWindow
      );
      this.orientationSlidingWindow.length = 0;
    }
  }

  // Function that calculates the current Angle (0-360deg) of View of Car
  getAngleOfViewBbox(boundingBoxDetections) {
    this.currentAngleFromBbox(boundingBoxDetections);

    // Return if Angle is not clear.
    if (this.aggregatedAngle === -1) return this.aggregatedAngle;

    /*
        // If aggegratedAngle = -1, use the mode of angleCollectionList.
        if (this.aggregatedAngle === -1)
            if (this.angleCollectionList.length === this.angleCollectionListLengthThreshold)
                this.aggregatedAngle = utilFuncs.getMode(this.angleCollectionList) 

        // Keep track of the aggregatedAngle values in a list
        this.angleCollectionList.push(this.aggregatedAngle);

        // Trim the angleCollectionList to a fixed length i.e. angleCollectionListLengthThreshold
        if (this.angleCollectionList.length > this.angleCollectionListLengthThreshold)
            this.angleCollectionList.shift();
        */

    // If aggregatedOrientation is 'Unclear', use the mode of orientationCollectionList.
    if (
      this.aggregatedOrientation === "Unclear" &&
      this.aggregatedAngle >= 20 &&
      this.aggregatedAngle <= 160
    )
      if (
        this.orientationCollectionList.length ===
        this.orientationCollectionListLengthThreshold
      )
        this.aggregatedOrientation = utilFuncs.getMode(
          this.orientationCollectionList
        );

    // Keep track of the aggregatedOrientation values in a list.
    this.orientationCollectionList.push(this.aggregatedOrientation);

    // Trim the orientationCollectionList to a fixed length i.e. orientationCollectionListLengthThreshold
    if (
      this.orientationCollectionList.length >
      this.orientationCollectionListLengthThreshold
    )
      this.orientationCollectionList.shift();

    // if aggregatedOrientation is still "Unclear", and the angle lies within [20,160], set aggregatedAngle to -1 and return
    if (
      this.aggregatedOrientation === "Unclear" &&
      this.aggregatedAngle >= 20 &&
      this.aggregatedAngle <= 160
    ) {
      this.aggregatedAngle = -1;
      return this.aggregatedAngle;
    }

    // Determine the actual Angle value (0-360deg) based on the orientation
    if (this.aggregatedOrientation === "Left")
      return 360 - this.aggregatedAngle;
    else return this.aggregatedAngle;
  }

  // Function that calculates the current Angle (0-360deg) of View of Car using the Gyroscope/Compass
  getAngleOfViewGyro(boundingBoxDetections) {
    /* At the beginning of the video, use the boundingBoxDetections to get the initial angle of view.
           Keep processing until you get acceptable Aggregated Angle and Orientation. */
    if (
      this.aggregatedAngle === constants.START_FLAG ||
      this.aggregatedOrientation === constants.START_FLAG
    ) {
      this.currentAngleFromBbox(boundingBoxDetections);

      // Start ReInitializing if the Aggregated Angle is still -1
      if (this.aggregatedAngle === -1) {
        // console.log("Still initializing Angle; it is -1 ...")
        this.aggregatedAngle = constants.START_FLAG;
        this.aggregatedOrientation = constants.START_FLAG;
        return -1;
      }

      // Return the angle based on the value of Aggregated Orientation
      if (this.aggregatedOrientation === "Unclear") {
        // Start ReInitializing if the Aggregated Orientation is still Unclear and Angle is between [20,160].
        if (this.aggregatedAngle >= 20 && this.aggregatedAngle <= 160) {
          // console.log("Still initializing Orientation; it is Unclear ...")
          this.aggregatedAngle = constants.START_FLAG;
          this.aggregatedOrientation = constants.START_FLAG;
          return -1;
        } else return this.aggregatedAngle;
      }

      // Determine the actual Angle value (0-360deg) based on the orientation
      else if (this.aggregatedOrientation === "Left")
        return 360 - this.aggregatedAngle;
      else if (this.aggregatedOrientation === "Right")
        return this.aggregatedAngle;

      return -1;
    }

    // this.currentAngleFromBbox(boundingBoxDetections);

    // if (this.aggregatedAngle !== -1) {
    //     // Determine the actual Angle value (0-360deg) based on the orientation
    //     if (this.aggregatedOrientation === "Left")
    //         return 360 - this.aggregatedAngle;
    //     else
    //         return this.aggregatedAngle;
    // }
    // console.log("aggregatedAngle", this.aggregatedAngle, "aggregatedOrientation", this.aggregatedOrientation)
    return this.angleGyroEstimator.getAngleOfFrame(
      this.aggregatedAngle,
      this.aggregatedOrientation
    );
  }

  // Function that determines the parts covered (with a high degree of confidence) in a frame and adds it to actualPartsCoveredMap if not already added
  setActualPartsCoveredMap(boundingBoxDetections, angle) {
    // Get a list of parts (in a frame) that can be said to have been covered with a high degree of confidence.
    let partsCoveredWithConfidence =
      this.partCoverageEstimator.getPartsCoveredWithConfidence(
        boundingBoxDetections,
        angle
      );
    // console.log("partsCoveredWithConfidence in feedback", partsCoveredWithConfidence)

    // Update the actualPartsCoveredMap with the newly covered Parts, if any.
    partsCoveredWithConfidence.forEach((part) => {
      for (let [region, partSet] of Object.entries(
        this.referencePartsCoveredMap
      )) {
        if (partSet.has(part)) this.actualPartsCoveredMap[region].add(part);
      }
    });
  }

  // Function that compares actualPartsCoveredMap with referencePartsCoveredMap to determine if all parts in a regions have been covered or not.
  setRegionsCovered() {
    for (let [region, partSet] of Object.entries(this.actualPartsCoveredMap)) {
      // if all parts of the region have been covered, then that region is complete (hence 1).
      if (partSet.size === this.referencePartsCoveredMap[region].size)
        this.regionsCoveredMap[region] =
          constants.REGION_COVERAGE_STATUS.FULLY_COVERED;
      // if all parts of the region have not been covered, then that region is not complete (hence 2).
      else if (
        partSet.size > 0 &&
        partSet.size < this.referencePartsCoveredMap[region].size
      )
        this.regionsCoveredMap[region] =
          constants.REGION_COVERAGE_STATUS.PARTIALLY_COVERED;
    }
  }

  // Check if User is moving too fast
  performSpeedCheck() {
    let userMovingTooFast = this.highSpeedDetector.checkHighSpeed(
      this.regionsCoveredMap
    );

    if (userMovingTooFast) {
      this.highSpeedCounter++;
      this.showhighSpeedMessageCounter = 0;
    }

    if (
      this.showhighSpeedMessageCounter >= 0 &&
      this.showhighSpeedMessageCounter <
        this.showAbruptMovementWarningMessageCounterThreshold
    ) {
      this.showhighSpeedMessageCounter++;
      this.feedbacks.push(this.highSpeedMessage);
    } else this.showhighSpeedMessageCounter = -1;

    if (this.highSpeedCounter >= this.highSpeedCounterThreshold)
      this.videoFreezeStatus = true;
  }

  // Function that uses the DeviceMotionEvent to check for abrupt movements
  performAbruptMovementCheck() {
    let abruptMovementDetected =
      this.abruptMovementDetector.checkAbruptMovement();

    if (abruptMovementDetected) {
      this.abruptMovementCounter++;
      this.showAbruptMovementWarningMessageCounter = 0;
    }

    // Show Abrupt movement warning message for a few frames
    if (
      this.showAbruptMovementWarningMessageCounter >= 0 &&
      this.showAbruptMovementWarningMessageCounter <
        this.showAbruptMovementWarningMessageCounterThreshold
    ) {
      this.showAbruptMovementWarningMessageCounter++;
      this.feedbacks.push(this.abruptMovementMessage);
    } else this.showAbruptMovementWarningMessageCounter = -1;

    // Freeze the video if Abrupt movement warning counter exceeds the threshold
    if (this.abruptMovementCounter >= this.abruptMovementCounterThreshold)
      this.videoFreezeStatus = true;
  }

  checkAspectRatioOfFrontGlass(boundingBoxDetection) {
    let [wdth, ht] = boundingBoxDetection["bbox"].slice(2);
    if (ht <= wdth) return true;
    else return false;
  }

  checkAndChangeBumperToIsometric(boundingBoxDetection, angle) {
    if (
      (angle >= 40 && angle <= 60) ||
      (angle >= 120 && angle <= 140) ||
      (angle >= 220 && angle <= 240) ||
      (angle >= 300 && angle <= 320)
    )
      return boundingBoxDetection["label"] + "_isometric";
    else return boundingBoxDetection["label"];
  }

  refinePartDetections(boundingBoxDetections, angle) {
    let refinedBoundingBoxDetections = [];
    for (let i = 0; i < boundingBoxDetections.length; i++) {
      let boundingBoxDetection = boundingBoxDetections[i];

      if (boundingBoxDetection["label"] === "front_glass") {
        if (this.checkAspectRatioOfFrontGlass(boundingBoxDetection))
          refinedBoundingBoxDetections.push(boundingBoxDetection);
      } else if (boundingBoxDetection["label"].includes("bumper")) {
        boundingBoxDetection["label"] = this.checkAndChangeBumperToIsometric(
          boundingBoxDetection,
          angle
        );
        refinedBoundingBoxDetections.push(boundingBoxDetection);
      } else refinedBoundingBoxDetections.push(boundingBoxDetection);
    }
    return refinedBoundingBoxDetections;
  }

  getRegionFromAngle(angle) {
    if (angle <= 22.5 || angle >= 337.5) return constants.REGIONS.FRONT;
    else if (angle <= 67.5) return constants.REGIONS.FRONT_ISOMETRIC_RIGHT;
    else if (angle <= 112.5) return constants.REGIONS.SIDE_RIGHT;
    else if (angle <= 157.5) return constants.REGIONS.REAR_ISOMETRIC_RIGHT;
    else if (angle <= 202.5) return constants.REGIONS.REAR;
    else if (angle <= 247.5) return constants.REGIONS.REAR_ISOMETRIC_LEFT;
    else if (angle <= 292.5) return constants.REGIONS.SIDE_LEFT;
    else if (angle <= 337.5) return constants.REGIONS.FRONT_ISOMETRIC_LEFT;
  }

  /* function that checks the direction of the camera. If the focus of the camera towards the 
  lower side of the vehicle and upper panel of the vehicle yet to detect then it will shows a nudge
  towards upper side of the screen*/

  getNudgeFlag(partCoveredMap, currentRegion, boundingBoxDetections) {
    // Get the nudge detection from single frame detection
    let nudge_data = this.nudges.getNudgeFromSingleFrameDetection(
      partCoveredMap,
      currentRegion,
      boundingBoxDetections
    );
    console.log(nudge_data, "nudge_data");
    let nudge = nudge_data["Nudge"];
    let partToPoint = nudge_data["partToPoint"];
    console.log("Nudge and partToPoint ", nudge, partToPoint);

    // Update the nudge list and check for window size condition
    if (this.nudgeWindow.includes(nudge) || !this.nudgeWindow.length) {
      this.nudgeWindow.push(nudge);
    } else {
      this.nudgeWindow = [];
      this.nudgeWindow.push(nudge);
    }
    console.log(this.nudgeWindow.length, "this.nudgeWindow.length");
    console.log(this.nudgeWindow, "this.nudgeWindow");

    // Check if the window size condition is met and return the appropriate nudge flag
    if (
      this.nudgeWindow.length &&
      this.nudgeWindow.length % this.nContinuousFrameForNudge === 0
    ) {
      console.log(
        "Nudge and partToPoint in retunr  ",
        this.nudgeWindow[0],
        partToPoint
      );
      return { partToPoint: partToPoint, Nudge: this.nudgeWindow[0] };
    }

    return { partToPoint: "", Nudge: "NA" };
  }

  /* Function that checks for Distance conditions, No Detections, Parts and Regions missed. 
        It then freezes the video based on the checks, and returns a list of feedbacks. */
  performChecksAndGetWarningMessage(boundingBoxDetections, processedFrame) {
    // Clearing Warning Message
    this.feedbacks.length = 0;
    this.videoFreezeStatus = false;

    if (
      this.aggregatedAngle === constants.START_FLAG ||
      this.aggregatedOrientation === constants.START_FLAG
    )
      this.feedbacks.push(this.initializingAngleMessage);
    else this.feedbacks.push(this.genericMessage);

    // Check if Too Zoomed in / Too Far Away
    this.performDistanceCheck(boundingBoxDetections);

    // Check for NoDetections
    this.performDetectionCheck(boundingBoxDetections, processedFrame);

    // Check for Abrupt Movements
    this.performAbruptMovementCheck();

    // Getting angle of View (0-360deg)
    //let angleOfView = this.getAngleOfViewBbox(boundingBoxDetections);
    this.currentAngle = this.getAngleOfViewGyro(boundingBoxDetections);
    // console.log(this.getAngleOfViewGyro(boundingBoxDetections))

    if (this.currentAngle !== -1)
      this.currentRegion = this.getRegionFromAngle(this.currentAngle);

    if (this.pausePartCoveringAndFrameUpload)
      return [
        this.currentAngle,
        this.currentRegion,
        this.regionsCoveredMap,
        this.feedbacks,
        this.videoFreezeStatus,
        this.currentNudge,
      ];

    // Use the Detected parts in the frame, and update the actualPartsCoveredMap, only if the angle is valid.
    if (this.currentAngle !== -1) {
      boundingBoxDetections = this.refinePartDetections(
        boundingBoxDetections,
        this.currentAngle
      );
      this.setActualPartsCoveredMap(boundingBoxDetections, this.currentAngle);
    }

    // Determine the Regions Covered.
    this.setRegionsCovered();

    // Check if User is moving too fast
    this.performSpeedCheck();

    // show nudges
    if (this.currentAngle !== -1){
      this.currentNudge = this.getNudgeFlag(
      this.actualPartsCoveredMap,
      this.currentRegion,
      boundingBoxDetections
      );
    }
    console.log("this.currentNudge", this.currentNudge, "%%%%%%%%%%%%%%%%");

    return [
      this.currentAngle,
      this.currentRegion,
      this.regionsCoveredMap,
      this.feedbacks,
      this.videoFreezeStatus,
      this.currentNudge,
    ];
  }

  sortFramesOfRegion(regionFrameDetails) {
    if (regionFrameDetails.length <= this.nMaxFramesUploadedPerRegion)
      return regionFrameDetails;

    const sortedRegionFrameDetails = _.sortBy(
      regionFrameDetails,
      (frame_detail) => frame_detail["detections"].length
    );
    const uniqueAngleSortedRegionFrameDetails = _.uniq(
      sortedRegionFrameDetails,
      (frame_detail) => frame_detail["angle"]
    );
    if (
      uniqueAngleSortedRegionFrameDetails.length <=
      this.nMaxFramesUploadedPerRegion
    )
      return uniqueAngleSortedRegionFrameDetails;

    const uniqueAngleMultipleSortedRegionFrameDetails =
      uniqueAngleSortedRegionFrameDetails.filter(
        (frame_detail) =>
          !(frame_detail["angle"] % this.frameSelectionAngleStepThresh)
      );
    return uniqueAngleMultipleSortedRegionFrameDetails.slice(
      0,
      this.nMaxFramesUploadedPerRegion
    );
  }

  sortFramesOfRegionV2(regionFrameDetails) {
    // console.log("regionFrameDetailsV2", regionFrameDetails);
    if (regionFrameDetails.length === 0) {
      // console.log("empty regionFrameDetails")
      return regionFrameDetails;
    }

    const maxNumberOfFrameToUpload =
      constants.MAX_NUMBER_OF_FRAME_PER_REGION[regionFrameDetails[0]["region"]];
    const partListForCurrentRegion = [
      ...this.referencePartsCoveredMap[regionFrameDetails[0]["region"]],
    ];
    // console.log("partListForCurrentRegion", partListForCurrentRegion)
    const uploadFrameFlag = partListForCurrentRegion.some(
      (part) => this.currentUploadedPartCount[part] < 2
    );

    let framesToPushV2 = [];

    if (!uploadFrameFlag) {
      // console.log("all_parts are covered")
      return framesToPushV2;
    }
    // console.log("uploadFrameFlag", uploadFrameFlag)
    // let sortedRegionFrameDetails = _.sortBy(
    //   regionFrameDetails,
    //   (frame_detail) => frame_detail["detections"].length
    // ).reverse();
    let sortedRegionFrameDetails = _.sortBy(
      regionFrameDetails,
      (frame_detail) => (frame_detail?.detections ? frame_detail.detections.length : 0)
    ).reverse();
    // console.log("sortedRegionFrameDetails length_initial", sortedRegionFrameDetails.length)
    sortedRegionFrameDetails = sortedRegionFrameDetails.filter(
      (regionFrameDetail) => {
        if (!regionFrameDetail || !regionFrameDetail.detections) {
          // Skip if frame_detail or detections is undefined
          return false;
        }
        let uploadFrameFlagInner = false;
        // get fully covered from the frame
        const coveredPartsInFrame =
          this.partCoverageEstimator.getPartsCoveredWithConfidence(
            regionFrameDetail["detections"],
            regionFrameDetail["angle"]
          );
        // update currentUploadedPartCount
        // console.log("coveredPartsInFrame", coveredPartsInFrame)
        coveredPartsInFrame.forEach((part) => {
          if (
            partListForCurrentRegion.includes(part) &&
            this.currentUploadedPartCount[part] < 2
          ) {
            uploadFrameFlagInner = true;
            // console.log(part, "this.currentUploadedPartCount", this.currentUploadedPartCount)
            // console.log("this.actualPartsCoveredMap", this.actualPartsCoveredMap)
            // console.log("angle",regionFrameDetail["angle"], "region", regionFrameDetail["region"])
            // console.log(this.currentUploadedPartCount[part], "count", part)
            this.currentUploadedPartCount[part]++;
            // console.log("this.currentUploadedPartCount", this.currentUploadedPartCount)
          }
        });

        if (
          framesToPushV2.length < maxNumberOfFrameToUpload &&
          uploadFrameFlagInner
        ) {
          framesToPushV2.push(regionFrameDetail);
          return false; // Remove this frame from sortedRegionFrameDetails
        }
        // console.log("this frame is not going to upload because its part already covered")
        return true;
      }
    );
    // console.log("sortedRegionFrameDetails length_after_pahse1", sortedRegionFrameDetails.length)
    if (framesToPushV2.length < maxNumberOfFrameToUpload) {
      const perfectAngleCurrentRegion =
        constants.PERFECT_ANGLE_PER_REGION[regionFrameDetails[0]["region"]];

      const perfectAngleFrame = sortedRegionFrameDetails.find(
        (regionFrameDetail) =>
          regionFrameDetail["angle"] === perfectAngleCurrentRegion
      );
      // console.log("perfectAngleCurrentRegion", perfectAngleCurrentRegion, perfectAngleFrame)
      if (perfectAngleFrame) {
        framesToPushV2.push(perfectAngleFrame);
        sortedRegionFrameDetails = sortedRegionFrameDetails.filter(
          (frame) => frame !== perfectAngleFrame
        );
      }
    }
    // console.log("sortedRegionFrameDetails length_after_pahse2", sortedRegionFrameDetails.length)
    let remainingFrameCount = maxNumberOfFrameToUpload - framesToPushV2.length;
    // console.log(remainingFrameCount, maxNumberOfFrameToUpload , framesToPushV2.length, "remainingFrameCount, maxNumberOfFrameToUpload , framesToPushV2.length")

    if (remainingFrameCount > 0 && sortedRegionFrameDetails.length > 0) {
      let filteredFrames = sortedRegionFrameDetails;
      // console.log("filteredFrames 1",filteredFrames.length)

      if (sortedRegionFrameDetails.length > remainingFrameCount) {
        filteredFrames = _.uniq(
          sortedRegionFrameDetails,
          (frame_detail) => frame_detail["angle"]
        );
        // console.log("filteredFrames angle",filteredFrames.length)
      }

      if (filteredFrames.length > remainingFrameCount) {
        filteredFrames = filteredFrames.filter(
          (frame_detail) =>
            frame_detail["angle"] % this.frameSelectionAngleStepThresh === 0
        );
        // console.log("filteredFrames %",filteredFrames.length)
      }

      if (filteredFrames.length > remainingFrameCount) {
        const indexListofframe = utilFuncs.equidistantIndex(
          remainingFrameCount,
          filteredFrames.length
        );
        // console.log("filteredFrames", filteredFrames.length, indexListofframe)
        indexListofframe.forEach((index) => {
          // console.log("filteredFrames[index]", filteredFrames[index])
          // console.log(framesToPushV2.length)
          framesToPushV2.push(filteredFrames[index]);
          // console.log(framesToPushV2.length)
          // console.log(framesToPushV2, "ssdfghs")
        });
      } else {
        // console.log("filteredFrames", filteredFrames.length)
        framesToPushV2.push(...filteredFrames);
      }
    }
    // console.log("framesToPushV2 length", framesToPushV2.length)
    return framesToPushV2;
  }
  getFramesToPush(frameEncoded, boundingBoxDetections) {
    let framesToPush = [];
    // console.log("this.previousRegion !== this.currentRegion ", this.previousRegion ,this.currentRegion )
    if (
      this.previousRegion !== this.currentRegion ||
      this.checkIfAllRegionsCovered()
    ) {
      // if (this.stage > 1) {
      //     if ((this.initialCoverageStatusPreviousRegion !== constants.REGION_COVERAGE_STATUS.FULLY_COVERED))
      //         // && (this.uploadedFramesAngleMap[this.previousRegion].size < this.nMaxFramesUploadedPerRegion))
      //         framesToPush = this.sortFramesOfRegion(this.regionFrameDetails)
      // }
      // else
      let framesToPush1 = this.sortFramesOfRegion(this.regionFrameDetails);
      let framesToPush2 = this.sortFramesOfRegionV2(this.regionFrameDetailsv2);
      framesToPush.push(framesToPush1);
      framesToPush.push(framesToPush2);
      // console.log(framesToPush2)

      this.previousRegion = this.currentRegion;
      this.initialCoverageStatusPreviousRegion =
        this.regionsCoveredMap[this.previousRegion];
      this.regionFrameDetails = [];
      this.regionFrameDetailsv2 = [];
    }

    let notProbablePartDetectedFlag = false;

    if (boundingBoxDetections.length === 0) {
      notProbablePartDetectedFlag = true;
    }
    for (let i = 0; i < boundingBoxDetections.length; i++) {
      notProbablePartDetectedFlag = false;
      let detection_classLabel = boundingBoxDetections[i]["label"];
      if (
        !(
          this.referencePartsCoveredMap[this.currentRegion].has(
            detection_classLabel
          ) ||
          this.referencePartsCoveredMap[this.currentRegion].has(
            "left_" + detection_classLabel
          ) ||
          this.referencePartsCoveredMap[this.currentRegion].has(
            "right_" + detection_classLabel
          )
        )
      )
        notProbablePartDetectedFlag = true;

      // if (!notProbablePartDetectedFlag) break;
    }
    // console.log(notProbablePartDetectedFlag, "notProbablePartDetectedFlag")
    if (!notProbablePartDetectedFlag && frameEncoded) {
      this.regionFrameDetails.push({
        angle: this.currentAngle,
        region: this.currentRegion,
        frameEncoded: frameEncoded,
        detections: boundingBoxDetections,
        regionsMap: this.regionsCoveredMap,
        feedbacks: this.feedbacks,
      });
      this.regionFrameDetailsv2 = [...this.regionFrameDetails];
      // console.log("this.regionFrameDetailsv2 length push", this.regionFrameDetailsv2.length)
    }
    // console.log("this.regionFrameDetailsv2 push", this.regionFrameDetailsv2)

    if (this.regionFrameDetails.length > this.nMaxFramesCurrentRegion)
      this.regionFrameDetails = this.regionFrameDetails.slice(
        Math.floor(this.nMaxFramesCurrentRegion / 2)
      );

    if (this.regionFrameDetailsv2.length > this.nMaxFramesCurrentRegion) {
      let indexListofframe = utilFuncs.equidistantIndex(
        this.nMaxFramesCurrentRegion,
        this.regionFrameDetailsv2.length
      );
      this.regionFrameDetailsv2 = indexListofframe.map(
        (frame) => this.regionFrameDetailsv2[frame]
      );
    }

    return {
      framesToPush: framesToPush,
      currentUploadedPartCount: this.currentUploadedPartCount,
    };
  }

  checkIfAllRegionsCovered() {
    return Object.values(this.regionsCoveredMap).every(
      (val) => val === constants.REGION_COVERAGE_STATUS.FULLY_COVERED
    );
  }
}
