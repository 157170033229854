import React, { Fragment, useContext, useEffect } from "react";
import { AppContext } from "../context";
import { userLogs , } from "../context/utils";
import rotate from "../img/rotate.json";
import Button from "./Button";

const Rotate = () => {
  const { config, autoRotate } = useContext(AppContext);
  let isAndroid = /Android/.test(navigator.userAgent);

  // userLogs({position: 8, last_page: "rotate page"})
  useEffect(() => {
    const setDimensions = () => {

      const processElement = document.querySelector(".rotate_size");
      if (processElement) {
        processElement.style.height = `${window.innerHeight}px`;
      }
    };

    // Set dimensions on load
    setDimensions();

    // Update dimensions on window resize
    window.addEventListener('resize', setDimensions);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', setDimensions);
    };
  }, [window.innerHeight]);

  const switchLandscape = () => {
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element
        .requestFullscreen()
        .then(() => {
          if (screen.orientation && screen.orientation.lock) {
            screen.orientation
              .lock("landscape")
              .then(function success() {})
              .catch(function error(e) {
                console.log(e.message);
              });
          } else if (
            window.screen &&
            window.screen.orientation &&
            window.screen.orientation.lock
          ) {
            window.screen.orientation
              .lock("landscape")
              .then(function success() {})
              .catch(function error(e) {
                console.log(e.message);
              });
          }
        })
        .catch((error) => {
          // console.log('Failed to enter fullscreen mode:', error);
        });
    }
  };
  return (
    <Fragment className = "rotate_size">
      <lottie-player
        id="rotate-image-1"
        src={rotate}
        background="transparent"
        speed="1"
        loop
        autoplay
      ></lottie-player>
      <h2 style={{ textAlign: "center", padding: "10px" }}>
        {config["rotate-page"]["text"]}
      </h2>
      {isAndroid && autoRotate && (
        <Button
          wide={false}
          onclick={switchLandscape}
          text={
            Object.keys(config["rotate-page"]).includes("rotateBtnTxt")
              ? config["rotate-page"]["rotateBtnTxt"]
              : "Click to Rotate"
          }
        />
      )}
    </Fragment>
  );
};

export default Rotate;
