import { PartDetector } from "../partdetection_new/PartDetector";

let totalImagesQueue = 0;
let imageQueue = [];
let backupImages = [];
let imageSending = false;
let imageSending2 = false;
let imageSending3 = false;
let count = 0;
let interval = null;
let interval2 = null;
let token = "";
let userEmail = "";
let location = { latitude: 0, longitude: 0 };
let inspectionId = "";
let retake = 0;
let abourt = true;
let rejectedImages = 0;
let OnceUpload = true;
let processCompleted = false;
let logBugs = true;
let imginterval;
let totalVideos = 0;
let vmgFlowType = null;
let vmgUrl = null;
let authorization = null;
let customHiibVmg = false;
let stopResizing = [false];
let setLoggingstatus = "true";

let partDetector = new PartDetector();
let modelPromise = {}
let modelReHitCount = 0

export const startModel = () =>{
  try{
    modelPromise = partDetector.loadModelFromURL();
  }catch (e){
    if(modelReHitCount < 4){
      startModel()
    }
    modelReHitCount = modelReHitCount + 1
  }
}
export const getModel = () =>{
  return modelPromise
}


let feedbackValue = "";
export const setLogging = (v) => (setLoggingstatus = v);

export const setFeedBackStatus = (v) => (feedbackValue = v);
export const feedBackStatus = () => {
  return feedbackValue;
};

export const initializeToken = (tk) => (token = tk);
export const initializeEmail = (email) => (userEmail = email);
export const initializeLocation = (loc) => (location = loc);
export const initializeInspectionId = (id) => (inspectionId = id);
export const initializeretake = (rtk) => (retake = rtk);
export const setVmgFlowType = (data) => (vmgFlowType = data);
export const setVmgUrl = (data) => (vmgUrl = data);
export const setAuthorization = (data) => (authorization = data);
export const setCustomHiibVmg = (data) => (customHiibVmg = data);
export const setResizing = (data) => (stopResizing = data);

export const makeRequest = async (URL, DATA) => {
  const res = await fetch(URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    // signal: abortController,
    body: JSON.stringify(DATA),
  });
  return res;
};
export const makeRequestVideo = async (DATA) => {
  totalImagesQueue = totalImagesQueue + 1;
  totalVideos++;
  try {
    const res = await fetch("/uploadvideo", {
      method: "POST",
      body: DATA,
    });
    if (res.ok) {
      res.json().then((res1) => {
        if (res1.status === "error") {
          totalVideos--;
        } else if (res1.status === "success") {
          totalVideos--;
        }
      });
    } else {
      totalVideos--;
    }
    return res;
  } catch (e) {
    totalVideos--;
  }
};
export const getCountData = () => {
  return count >= 1;
};

const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (error) => reject(error);
    img.src = src;
  });
};

export const addImageToList = async (image) => {
  if (stopResizing[0]) {
    if (Array.isArray(image)) {
      imageQueue.push(image);
    } else {
      if (image.tag === "Selfie") {
        imageQueue.push(image);
      } else {
        console.log(`coversion image to ${stopResizing[1]} `);
        const image1 = await loadImage(image.imgData);
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = stopResizing[1].width;
        canvas.height = stopResizing[1].height;
        context.drawImage(
          image1,
          0,
          0,
          stopResizing[1].width,
          stopResizing[1].height
        );
        let base64Data = canvas.toDataURL("image/jpeg");
        image["imgData"] = base64Data;
        imageQueue.push(image);
      }
    }
  } else {
    if (Array.isArray(image)) {
      imageQueue.push(image);
    } else {
      if (image.tag === "Selfie") {
        imageQueue.push(image);
      } else {
        console.log("coversion image to 1280 and 720");
        const image1 = await loadImage(image.imgData);
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = 1280;
        canvas.height = 720;
        context.drawImage(image1, 0, 0, 1280, 720);
        let base64Data = canvas.toDataURL("image/jpeg");
        image["imgData"] = base64Data;
        imageQueue.push(image);
      }
    }
  }
  totalImagesQueue = totalImagesQueue + 1;
};
export const imageUpload = async (inspectionId) => {
  if (logBugs) {
    imginterval = setInterval(() => {
      imgStatus(inspectionId);
      logBugs = false;
    }, 100);
  }
  if (imageQueue.length != 0 && imageSending != true) {
    imageSending = true;
    // let abortController = new AbortController();
    // clearInterval(interval)

    // interval = setInterval(() =>{
    //   abortController.abort();
    //   backupImages.push(imageQueue[0])
    //   imageQueue.splice(0, 1)
    //   imageSending = false
    //   clearInterval(interval)
    // }, 3000)

    try {
      const res = await makeRequest("/imageUpload", {
        token,
        file: imageQueue[0],
        email: userEmail,
        geolocation: location,
        count,
        inspectionId,
        vmgFlowType,
      });
      if (res.ok) {
        res.json().then((res1) => {
          if (res1.status === "error") {
            // clearInterval(interval)
            backupImages.push(imageQueue[0]);
            imageQueue.splice(0, 1);
            imageSending = false;
          } else if (res1.status === "success") {
            // clearInterval(interval)
            count++;
            imageQueue.splice(0, 1);
            imageSending = false;
          }
        });
      } else {
        // console.log("problem sending")
        backupImages.push(imageQueue[0]);
        imageQueue.splice(0, 1);
        imageSending = false;
      }
    } catch (err) {
      console.log(err);
      backupImages.push(imageQueue[0]);
      imageQueue.splice(0, 1);
      imageSending = false;
    }
  }
};

export const sendCoveredArea = async (allGreen, regionsMap) => {
  let region = {};
  let allPartsCovered = 0;

  if (allGreen) {
    allPartsCovered = 1;
  }

  region.F = regionsMap["Front"];
  region.B = regionsMap["Rear"];
  region.LFI = regionsMap["Front-Isometric-Left"];
  region.RFI = regionsMap["Front-Isometric-Right"];
  region.LRI = regionsMap["Rear-Isometric-Left"];
  region.RRI = regionsMap["Rear-Isometric-Right"];
  region.L = regionsMap["Side-Left"];
  region.R = regionsMap["Side-Right"];

  const res = await makeRequest("/sendAreaCovered", {
    allGreen: allPartsCovered,
    region,
    retake,
    token,
  });
};

export const setLog = async (message) => {
  await makeRequest("/setLog", { message, inspectionId });
};

export const backupImagesStatus = () => {
  // console.log("imageQueue", imageQueue.length, "backupImages", backupImages.length)
  if (backupImages.length > 0 && imageQueue.length === 0) {
    return true;
  } else {
    return false;
  }
};

export const finalUpload = async () => {
  // console.log('final upload started')
  if (imageQueue.length > 0) {
    return false;
  }
  if (backupImages.length != 0 && imageSending2 != true) {
    imageSending2 = true;
    // let abortController = new AbortController();
    // clearInterval(interval)
    // interval2 = setInterval(() =>{
    //   abortController.abort();
    //   backupImages.splice(0, 1)
    //   rejectedImages = rejectedImages + 1
    //   imageSending2 = false
    //   clearInterval(interval)
    // }, 6000)
    try {
      const res = await makeRequest("/imageUpload", {
        token,
        file: backupImages[0],
        email: userEmail,
        geolocation: location,
        count,
        inspectionId,
        vmgFlowType,
      });
      if (res.ok) {
        res.json().then((res1) => {
          if (res1.status === "error") {
            backupImages.splice(0, 1);
            imageSending2 = false;
          } else if (res1.status === "success") {
            // clearInterval(interval)
            count++;
            backupImages.splice(0, 1);
            imageSending2 = false;
          }
        });
      } else {
        backupImages.splice(0, 1);
        imageSending2 = false;
      }
    } catch (err) {
      console.log(err.message, err.name);
      backupImages.splice(0, 1);
      // rejectedImages = rejectedImages + 1
      imageSending2 = false;
    }
  }
  if (backupImages.length > 0) {
    return false;
  }

  try {
    if (!imageSending3) {
      imageSending3 = true;
      clearInterval(imginterval);
      const res = await makeRequest("/finalUpload", {
        count: count,
        inspectionId,
        email: userEmail,
        token,
        geolocation: location,
        vmgFlowType,
        vmgUrl,
        authorization,
        customHiibVmg,
      });
      if (res.ok) {
        let message = `(Total Images${totalImagesQueue - totalVideos})`;
        userLogs({ position: 5, fInal_image_upload: "yes", inspectionId });
        await makeRequest("/setLog", { message, inspectionId });
        let val = await res.json();
        if (val.status === "usedToken") {
          return "usedToken";
        } else {
          return true;
        }
      }
      userLogs({ position: 5, fInal_image_upload: "no", inspectionId });
      imageSending3 = false;
      return false;
    }
  } catch (err) {
    userLogs({ position: 5, fInal_image_upload: "no", inspectionId });
    imageSending3 = false;
    return false;
  }
};

export const totalImagesUploadStatus = () => {
  console.log({
    uploadedImage:
      totalImagesQueue -
      (imageQueue.length + backupImages.length + totalVideos),
    totalImage: totalImagesQueue,
  });
  return {
    uploadedImage:
      totalImagesQueue -
      (imageQueue.length + backupImages.length + totalVideos),
    totalImage: totalImagesQueue,
  };
};

export const QRDataUpload = async (data) => {
  await makeRequest("/qrData", { inspectionId, data, token });
};

export const formUpload = async (jsonFileWithDocuments, jsonFile, impData) => {
  let data = {
    jsonFileWithDocuments,
    jsonFile,
    impData,
  };

  await makeRequest("/sendForm", { inspectionId, token, data });
};

export const actualRegion = async (data, inspection_id) => {
  await makeRequest("/actualregion", { data, inspection_id });
};

export const vinStatus = async () => {
  try {
    const response = await fetch("/vinstatus");
    const result = await response.json();
    // console.log(result);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const ping = async () => {
  const res = await fetch("/ping");
  return res.ok;
};

export const lowLightImage = async () => {
  // console.log("luma hit")
  try {
    const response = await fetch("/lowLightImage");
    const result = await response.json();
    // console.log(result);
    return result["status"];
  } catch (error) {
    console.log(error);
  }
};
export const userLogs = async (data) => {
  if (setLoggingstatus === "true") {
    await fetch(
      process.env.REACT_APP_LAMBDA_LOG_URL,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );
  }
};

export const rejectionBatch = async (data) => {
  try {
    const response = await makeRequest("/rejectInspection", data);
    console.log(response);
    const result = await response.json();
    console.log(result);
    return result["status"];
  } catch (error) {
    console.log(error);
  }
};

export const addUserAgentData = async (data) => {
  try {
    const response = await makeRequest("/addUserAgentData", data);
    console.log(response);
    const result = await response.json();
    console.log(result);
    return result["status"];
  } catch (error) {
    console.log(error);
  }
};

export const gethiibMssg = async (data) => {
  try {
    const response = await makeRequest("/gethiibMssg", data);
    console.log(response);
    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const vin_detection = async (data) => {
  try {
    const response = await makeRequest("/vinDetection", data);
    console.log(response);
    const result = await response.json();
    console.log("``````VIN``````", typeof result.status, result.status);
    return result.status;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const imageFeedbackLambda = async (data) => {
  try {
    const response = await makeRequest("/imageFeedback", data);
    console.log(response);
    const result = await response.json();
    console.log(
      "``````imageFeedbackLambda``````",
      typeof result.status,
      result.status
    );
    return result.status;
  } catch (error) {
    console.log(error);
    return {
      feedback: "",
    };
  }
};

let datas;
let values = "";
const imgStatus = async (inspectionId) => {
  // console.log("errorr")
  datas = `Images uploading not including final Upload image  ${
    totalImagesQueue - (imageQueue.length + backupImages.length)
  } / ${totalImagesQueue}`;
  if (values !== datas) {
    // console.log(`Images uploading not including final Upload image  ${totalImagesQueue - (imageQueue.length + backupImages.length)} / ${totalImagesQueue}`)
    values = `Images uploading not including final Upload image  ${
      totalImagesQueue - (imageQueue.length + backupImages.length)
    } / ${totalImagesQueue}`;
    userLogs({
      position: 2,
      image_upload_status: `${
        totalImagesQueue - (imageQueue.length + backupImages.length)
      } / ${totalImagesQueue}`,
      inspectionId,
    });
  }
};
let pathData = [];
export const patLogs = async (data, inspe) => {
  !inspe && (inspe = inspectionId);
  pathData.push(data);
  userLogs({
    position: 7,
    user_step_covered: pathData,
    inspectionId: inspe,
  });
};

export const mlLogs = async (data) => {
  await patLogs(data, inspectionId);
};
